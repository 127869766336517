import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import IconButtons from "../../components/IconButtons/IconButtons";
import { filter_active_icon } from "../../constants/icons";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import {
  clearFilterAssessmentEvaluations,
  getAssessmentEvaluations,
  getAssessmentsFiltersData,
} from "../../store/assessments";
import Loader from "../../components/Loader/Loader";
import { calculateFilterCount, font_13_icon } from "../../utils/utils";
import FilterAssessments from "./FilterAssessments";
import AssessmentsTable from "./AssessmentsTable";

const Assessments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { bootcamp, module, submittedBy } = location?.state?.propsToPass || {};
  const dispatch = useDispatch();
  const params = useParams();
  const isAttendee = params?.user === "attendee";
  const isMentor = params?.user === "mentor";
  const isInterviewer = params?.user === "interviewer";

  const security = useSelector((state) => state?.security);
  const assessmentEvaluations = useSelector(
    (state) => state?.assessmentEvaluations
  );
  const assessmentEvaluationsLoading = assessmentEvaluations?.loading;
  const filtersDataLoading = assessmentEvaluations?.filtersDataLoading;
  const filtersDataList = assessmentEvaluations?.filterDataList?.data;
  const assessmentEvaluationsList =
    assessmentEvaluations?.assessmentEvaluationsList;
  const submittedByList = filtersDataList?.evaluationAttendees?.results?.map(
    (user) => user
  );

  const bootcampList = filtersDataList?.bootcamps?.results?.map(
    ({ id, name, modules, status, competency, startDate }) => ({
      id,
      name,
      modules,
      status,
      competency,
      startDate,
    })
  );
  let initialBootcamp =
    bootcampList?.find((it) => it?.status === "ACTIVE") ||
    bootcampList?.find((it) => it?.status === "SCHEDULED") ||
    bootcampList?.[0];

  const initialBootcampId = initialBootcamp?.id;

  const [isLoading, setIsLoading] = useState(false);

  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (value) => {
    setOffsetChangedByUser(true);
    setOffset(value - 1);
    setCurrentPage(value);
  };
  const totalResult = assessmentEvaluationsList?.data?.totalResults;

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  const breadcrumbs = [
    {
      label: "Assessments",
    },
    {
      label: isAttendee
        ? "As Attendee"
        : isMentor
        ? "As Mentor"
        : isInterviewer
        ? "As Interviewer"
        : "",
    },
  ];

  const initialFilterAssessmentEvaluations = {
    asAttendee: isAttendee,
    asMentor: isMentor,
    asInterviewer: isInterviewer,
    bootcampId: bootcamp ? bootcamp : initialBootcamp || null,
    moduleId: module ? module : null,
    offset,
  };

  const [filterCount, setFilterCount] = useState(0);
  const [filterActivated, setFilterActivated] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterAssessmentEvaluations, setFilterAssessmentEvaluations] =
    useState(initialFilterAssessmentEvaluations);
  const [removeFilter, setRemoveFilter] = useState(false);
  const [offsetChangedByUser, setOffsetChangedByUser] = useState(true);

  const modulesList = filtersDataList?.bootcamps?.results?.find(
    (it) => it?.id === filterAssessmentEvaluations?.bootcampId?.id
  )?.modules;

  useEffect(() => {
    setOffset(0);
    setCurrentPage(1);
    setFilterAssessmentEvaluations(initialFilterAssessmentEvaluations);
    setFilterActivated(true);
    setFilterCount(1);
  }, [isAttendee]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getAssessmentsFiltersData({
          asAttendee: isAttendee,
          asMentor: isMentor,
          asInterviewer: isInterviewer,
        })
      );
    };

    if (security?.loggedInUser?.id) {
      fetchData();
    }

    return () => {
      setOffsetChangedByUser(true);
      dispatch(clearFilterAssessmentEvaluations());
    };
  }, [dispatch, isAttendee, isMentor]);

  const handleViewEvaluation = (
    bootcampId,
    moduleId,
    assessmentId,
    evaluationId
  ) => {
    navigate(
      moduleId
        ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment/${assessmentId}/evaluations/${evaluationId}`
        : `/bootcamp/${bootcampId}/assessment/${assessmentId}/evaluations/${evaluationId}`,
      { state: { viaAssessmentList: true } }
    );
  };

  const handlePlayEvaluation = (evaluationId) => {
    navigate(`/evaluations/${evaluationId}`);
  };

  const fetchAssessmentEvaluations = async () => {
    const data = {
      ...filterAssessmentEvaluations,
      asAttendee: isAttendee,
      asMentor: isMentor,
      asInterviewer: isInterviewer,
      bootcampId:
        bootcamp?.id ||
        filterAssessmentEvaluations?.bootcampId?.id ||
        initialBootcampId,
      ...((module || filterAssessmentEvaluations?.moduleId) && {
        moduleId: module?.id || filterAssessmentEvaluations?.moduleId?.id,
      }),
      ...((submittedBy || filterAssessmentEvaluations?.submittedBy) && {
        submittedById:
          submittedBy?.id || filterAssessmentEvaluations?.submittedById?.id,
      }),
      status: filterAssessmentEvaluations?.status?.key,
      offset,
    };
    await dispatch(getAssessmentEvaluations(data));
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      offsetChangedByUser &&
      (bootcamp ||
        filterAssessmentEvaluations?.bootcampId?.id ||
        initialBootcampId)
    ) {
      fetchAssessmentEvaluations();
    }
  }, [
    dispatch,
    isAttendee,
    isMentor,
    isInterviewer,
    initialBootcampId,
    offset,
  ]);

  useEffect(() => {
    if (removeFilter) {
      fetchAssessmentEvaluations();
      setRemoveFilter(false);
    }
  }, [removeFilter]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  useEffect(() => {
    setFilterAssessmentEvaluations((prev) => {
      return {
        ...prev,
        bootcampId: bootcamp ? bootcamp : initialBootcamp || null,
        ...(module && { moduleId: module }),
        ...(submittedBy && { submittedById: submittedBy }),
        asAttendee: isAttendee,
        asMentor: isMentor,
        asInterviewer: isInterviewer,
      };
    });
    setFilterCount(
      bootcamp ? (module && submittedBy ? 3 : module || submittedBy ? 2 : 1) : 1
    );
    setFilterActivated(true);
  }, [initialBootcamp?.id]);
  ////////////////////////

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };
  const handleFilterClose = () => {
    setFilterOpen(false);
    initialFilterAssessmentEvaluations.asAttendee = isAttendee;
    setFilterAssessmentEvaluations({
      ...initialFilterAssessmentEvaluations,
      bootcampId: filterAssessmentEvaluations?.bootcampId,
    });
  };

  const handleFilterAssessmentEvaluationsSubmit = async () => {
    const { bootcampId, moduleId, sessionId, submittedById, status } =
      filterAssessmentEvaluations;

    const filterCount = calculateFilterCount({
      bootcampId,
      moduleId,
      sessionId,
      submittedById,
      status,
    });
    const data = {
      ...filterAssessmentEvaluations,
      status: status?.key || null,
      bootcampId: bootcampId?.id || null,
      moduleId: moduleId?.id || null,
      sessionId: sessionId?.id || null,
      submittedById: submittedById?.id || null,
      offset: 0,
    };

    if (filterCount || filterActivated) {
      dispatch(getAssessmentEvaluations(data));
      setFilterCount(filterCount);
      setFilterActivated(filterCount);
      initialFilterAssessmentEvaluations.asAttendee = isAttendee;
      initialFilterAssessmentEvaluations.bootcampId = bootcampId;
      setFilterAssessmentEvaluations(filterAssessmentEvaluations);
      setOffsetChangedByUser(false);
      setCurrentPage(1);
      setOffset(0);
    }
    setFilterOpen(false);
  };
  const handleFilterAssessmentEvaluationsRemove = async () => {
    initialFilterAssessmentEvaluations.asAttendee = isAttendee;
    await setFilterAssessmentEvaluations({
      ...initialFilterAssessmentEvaluations,
    });
    if (filterCount || filterActivated) {
      setOffsetChangedByUser(true);
      setCurrentPage(1);
      setOffset(0);
      setFilterCount(
        bootcamp
          ? module && submittedBy
            ? 3
            : module || submittedBy
            ? 2
            : 1
          : 1
      );
      setFilterActivated(true);
    }
    setFilterOpen(false);
    setRemoveFilter(true);
  };

  const handleChangeFilterAssessmentEvaluations = (name, value) => {
    const updatedFilters = { ...filterAssessmentEvaluations, [name]: value };
    if (name === "bootcampId") {
      updatedFilters.moduleId = null;
      updatedFilters.sessionId = null;
    } else if (name === "moduleId") {
      updatedFilters.sessionId = null;
    }
    setFilterAssessmentEvaluations(updatedFilters);
  };

  if (assessmentEvaluationsLoading || filtersDataLoading || isLoading)
    return <Loader />;
  return (
    <>
      <Grid container className="main-container">
        <BreadCrumb breadcrumbs={breadcrumbs} />

        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">
            {isAttendee
              ? "My Assessments"
              : isMentor
              ? "Mentee Assessments"
              : isInterviewer
              ? "Interviewee Assessments"
              : ""}
          </Typography>
          <Box className="header-right-icons">
            <Box
              className="filterButton margin-left-1"
              sx={{
                border: filterActivated
                  ? "2px solid #de1186"
                  : "1px solid #de118680",
              }}
            >
              <IconButtons
                height={font_13_icon}
                width={font_13_icon}
                image={filter_active_icon}
                handleClick={handleFilterOpen}
                tooltip="Add Filters"
                count={filterCount}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} className="body-container" sx={{ width: "100%" }}>
          <Grid container className="table-parent-container">
            <Paper
              elevation={0}
              className="flex-container-column exercise-paper-container"
            >
              {assessmentEvaluationsList?.data?.results?.length &&
              bootcampList ? (
                <>
                  <AssessmentsTable
                    getAssessmentsData={assessmentEvaluationsList}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    isAttendee={isAttendee}
                    isMentor={isMentor}
                    isInterviewer={isInterviewer}
                    handleViewEvaluation={handleViewEvaluation}
                    handlePlayEvaluation={handlePlayEvaluation}
                  />
                </>
              ) : (
                <Grid container className="no-data-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Evaluations Available"
                  />
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <FilterAssessments
        open={filterOpen}
        isAttendee={isAttendee}
        isMentor={isMentor}
        isInterviewer={isInterviewer}
        onClose={handleFilterClose}
        filterAssessmentEvaluations={filterAssessmentEvaluations}
        bootcampList={bootcampList}
        modulesList={modulesList}
        submittedByList={submittedByList}
        handleChangeFilterAssessmentEvaluations={
          handleChangeFilterAssessmentEvaluations
        }
        handleFilterAssessmentEvaluationsSubmit={
          handleFilterAssessmentEvaluationsSubmit
        }
        handleFilterAssessmentEvaluationsRemove={
          handleFilterAssessmentEvaluationsRemove
        }
      />
    </>
  );
};

export default Assessments;

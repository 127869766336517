import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import IconButtons from "../../../components/IconButtons/IconButtons";
import TablePagination from "../../../components/TablePagination/TablePagination";
import {
  down_sort_arrow_icon,
  up_sort_arrow_icon,
} from "../../../constants/icons";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubmitDetails,
  clearExercise,
  countExerciseFilter,
  getExerciseStatus,
  getOptionsForEditReviewer,
} from "../../../store/SessionView/exerciseStatusTab";
import {
  font_9,
  regex,
  exerciseStatusChip,
  calculateFilterCount,
  getStatus,
} from "../../../utils/utils";
import dayjs from "dayjs";
import CommentModal from "../../../components/CommentModal/CommentModal";
import Loader from "../../../components/Loader/Loader";
import NoDataAvailable from "../../../components/NoDataAvailable/NoDataAvailable";
import { addComment, getCommentList } from "../../../store/comments";
import UserTableAvatar from "../../../components/UserTableAvatar/UserTableAvatar";
import BootstrapTooltip from "../../../components/BootstrapTooltip/BootstrapTooltip";
import InfoIcon from "@mui/icons-material/Info";
import ExerciseTabFilter from "./ExerciseTabFilter";
import ReviewModal from "../../../components/ReviewModal/ReviewModal";
import ExerciseStatusActions from "./ExerciseStatusActions";
import SubmitModal from "../../../components/SubmitModal/SubmitModal";
import ReviewerEdit from "./ReviewerEdit";
import {
  AttendeeStatus,
  HelpTexts,
  sortInAscending,
  sortInDescending,
} from "../../../constants/constants";
import UserModuleBootcamp from "../../../components/UserModuleBootcamp/UserModuleBootcamp";
import { getDetails } from "../../../store/SessionView/detailsTab";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Asia/Kolkata";

const { DROPPED, SEPARATED } = AttendeeStatus;
const { NON_ACTIVE_ATTENDEE } = HelpTexts;
const initialEditReviewer = {
  competencyId: null,
  designationId: null,
  reviewerId: null,
  exerciseId: null,
};

const ExerciseTab = ({
  filterOpen,
  handleExerciseFilterClose,
  isHr,
  currentPage,
  setCurrentPage,
  filterExercise,
  setFilterExercise,
  initialFilterValues,
  setInitialFilterValues,
  initialFilterApplied,
  setInitialFilterApplied,
  initialFilterExercise,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const attendeeStatuses = useSelector(
    (state) => state?.utility?.constants?.attendeeStatuses
  );
  const exerciseData = useSelector((state) => {
    return state?.exerciseStatusTab;
  });
  const commentsList = useSelector((state) => state?.commentsList);
  const Details = useSelector((state) => {
    return state?.detailsTab;
  });
  const loading = exerciseData?.loading;
  const exerciseLoading = exerciseData?.exerciseLoading;
  const addSubmitDetailsLoading = exerciseData?.addSubmitDetailsLoading;
  const [isLoading, setIsLoading] = useState(false);
  const exerciseList = exerciseData?.exercise?.data?.results;
  const batches = exerciseData?.filtersForExerciseTab?.data?.batches?.results;
  const bootcamp = Details?.details?.data?.module?.bootcamps?.results;
  const competency =
    exerciseData?.filtersForExerciseTab?.data?.competencies?.results;
  const bootcampList =
    exerciseData?.filtersForExerciseTab?.data?.bootcamps?.results;
  const apiLoading = exerciseData?.apiLoading;

  const security = useSelector((state) => state?.security);

  const userCompetency = security?.loggedInUser?.competency;
  const permissions = Details?.details?.data?.permission;
  const isSpoc = permissions?.spoc;
  const isReviewer = permissions?.exerciseReviewer;
  const isAttendee = permissions?.attendee;
  const isMentor = permissions?.mentor;
  const isSessionPresenter = permissions?.sessionPresenter;
  const isModuleOngoing = Details?.details?.data?.module?.status === "ACTIVE";
  const currentBootcamp =
    Details?.details?.data?.module?.bootcamps?.results?.filter(
      (it) => it?.id === Number(params?.bootcampId)
    )[0];
  const isBootcampCompleted = currentBootcamp?.status === "COMPLETED";
  const canAccessActionItems =
    isSpoc ||
    isAttendee ||
    isReviewer ||
    isMentor ||
    isSessionPresenter ||
    isHr;
  const maxExerciseScore = Details?.details?.data?.maxExerciseScore;
  const exerciseDueDate = dayjs(
    Details?.details?.data?.exerciseSubmissionDueDate
  )?.format("DD/MM/YYYY hh:mm A");
  const moduleId = params?.moduleId;
  const bootcampId = params?.bootcampId;

  //////////////////// Comment Modal ////////////////////////////
  const [openComment, setOpenComment] = useState(false);
  const [exerciseId, setExerciseId] = useState(null);
  const [comments, setComments] = useState(null);
  const [commentDetails, setCommentDetails] = useState("");
  const [canAddComment, setCanAddComment] = useState(true);

  useEffect(() => {
    setComments(commentsList?.commentsList?.data?.results);
  }, [commentsList]);

  const handleCommentOpen = (event, id, canAddComment) => {
    event.stopPropagation();
    setExerciseId(id);
    dispatch(getCommentList({ exerciseId: id }));
    setCanAddComment(canAddComment);
    setOpenComment(true);
  };
  const handleCommentClose = () => {
    setCommentDetails("");
    setOpenComment(false);
  };
  const handleSubmit = async (getComment) => {
    const comment = getComment?.trim();
    if (comment) {
      await dispatch(addComment({ exerciseId, comment }));
      setCommentDetails("");
      dispatch(getCommentList({ exerciseId }));
    }
  };
  const handleInputComment = (comment) => {
    setCommentDetails(comment);
  };
  ////////////////////////////////////////////////////////////
  ////////////// sort table ///////////////////////////////////
  const [sortBy, setSortBy] = useState("attendeeName");
  const [orderBy, setOrderBy] = useState("ASC");

  const handleSorting = (order, sort) => {
    setOffsetChangedByUser(true);
    setSortBy(sort);
    setOrderBy(order);
  };
  ////////////////////////////////////////////////////////////
  /////////  pagination //////////////////////////////////////
  const totalResult = exerciseData?.exercise?.data?.totalResults;

  const [offset, setOffset] = useState(0);
  const [offsetChangedByUser, setOffsetChangedByUser] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (value) => {
    setOffsetChangedByUser(true);
    setOffset(value - 1);
    setCurrentPage(value);
  };
  ///////////////////////////////////////////////////////////
  //////////////////// Review Modal ////////////////////////////
  const competencyList =
    exerciseData?.optionsForEditReviewer?.data?.competencies?.results;
  const editReviewerLoading = exerciseData?.editReviewerLoading;
  const [editReviewerDetails, setEditReviewerDetails] =
    useState(initialEditReviewer);
  const [previousReviewerDetails, setPreviousReviewerDetails] =
    useState(initialEditReviewer);
  const [openReviewerEdit, setOpenReviewerEdit] = useState(false);
  const [editReviewerApiError, setEditReviewerApiError] = useState(false);
  const handleEditExerciseTabOpen = async (exerciseId, reviewer) => {
    if (!exerciseData?.optionsForEditReviewer?.data?.competencies?.results) {
      await dispatch(
        getOptionsForEditReviewer({ sessionId: params?.sessionId })
      );
    }
    const data = {
      ...editReviewerDetails,
      exerciseId: exerciseId,
      reviewerId: reviewer,
    };
    setEditReviewerDetails(data);
    setPreviousReviewerDetails(data);
    setOpenReviewerEdit(true);
  };
  const handleEditExerciseTabClose = () => {
    setOpenReviewerEdit(false);
    setEditReviewerDetails(initialEditReviewer);
    setPreviousReviewerDetails(initialEditReviewer);
  };

  const handleReviewerChange = (name, value) => {
    setEditReviewerApiError(false);
    setEditReviewerDetails({
      ...editReviewerDetails,
      [name]: value,
    });
  };

  const handleSubmitEditExercise = async () => {
    let apiValue;
    const submitDetails = {
      json: {
        reviewerId: editReviewerDetails?.reviewerId?.id,
        editReviewer: true,
      },
    };
    apiValue = await dispatch(
      addSubmitDetails({
        submitDetails,
        exerciseEditId: editReviewerDetails?.exerciseId,
        type: "EDIT_REVIEWER",
      })
    );
    if (apiValue?.error?.message === "Rejected") {
      setEditReviewerApiError(true);
    } else {
      const detailsResponse = await dispatch(
        getDetails({
          bootcampId: params?.bootcampId,
          moduleId: params?.moduleId,
          topicId: params?.topicId,
          sessionId: params?.sessionId,
        })
      );
      const permissions = detailsResponse?.payload?.data?.permission;
      setFilterExercise((prevFilterExercise) => {
        const updatedFilterExercise = {
          ...prevFilterExercise,
          ...(permissions?.mentor &&
            !permissions?.spoc &&
            !isHr && { showMentees: true }),
          showReviewees: permissions?.exerciseReviewer ? true : false,
          ...(permissions?.sessionPresenter &&
            !permissions?.spoc &&
            !isHr && { showAttendees: true }),
        };
        handleFilterExerciseSubmit(updatedFilterExercise);
        return updatedFilterExercise;
      });
      handleEditExerciseTabClose();
    }
  };

  useEffect(() => {
    if (
      openReviewerEdit &&
      exerciseData?.optionsForEditReviewer?.data?.competencies?.results
    ) {
      const defaultCompetency =
        exerciseData?.optionsForEditReviewer?.data?.competencies?.results?.filter(
          (it) => it?.id === currentBootcamp?.competency?.id
        )[0];
      const reviewerCompetency = editReviewerDetails?.reviewerId?.competency
        ? exerciseData?.optionsForEditReviewer?.data?.competencies?.results?.filter(
            (it) => it?.id === editReviewerDetails?.reviewerId?.competency?.id
          )[0]
        : defaultCompetency;
      const reviewerDesignation = editReviewerDetails?.reviewerId?.designation
        ? reviewerCompetency?.designations?.results?.filter(
            (it) => it?.id === editReviewerDetails?.reviewerId?.designation?.id
          )[0]
        : null;

      const data = {
        competencyId: reviewerCompetency,
        designationId: reviewerDesignation,
      };
      setEditReviewerDetails({
        ...editReviewerDetails,
        ...data,
      });
      setPreviousReviewerDetails({
        ...previousReviewerDetails,
        ...data,
      });
    }
  }, [
    openReviewerEdit,
    exerciseData?.optionsForEditReviewer?.data?.competencies?.results,
  ]);

  useEffect(() => {
    const competency = editReviewerDetails?.competencyId?.id;
    const previousCompetency = previousReviewerDetails?.competencyId?.id;
    if (competency && previousCompetency) {
      if (competency === previousCompetency) {
        setEditReviewerDetails({
          ...editReviewerDetails,
          designationId: previousReviewerDetails?.designationId,
          reviewerId: previousReviewerDetails?.reviewerId,
        });
      }
      if (competency !== previousCompetency) {
        setEditReviewerDetails({
          ...editReviewerDetails,
          designationId: null,
          reviewerId: null,
        });
      }
    }
  }, [editReviewerDetails?.competencyId]);

  useEffect(() => {
    const designation = editReviewerDetails?.designationId?.id;
    const previousDesignation = previousReviewerDetails?.designationId?.id;
    if (designation === previousDesignation && !!previousDesignation) {
      setEditReviewerDetails({
        ...editReviewerDetails,
        reviewerId: previousReviewerDetails?.reviewerId,
      });
    }
    if (designation !== previousDesignation && !!designation) {
      setEditReviewerDetails({
        ...editReviewerDetails,
        reviewerId: null,
      });
    }
  }, [editReviewerDetails?.designationId]);

  const [openSubmit, setOpenSubmit] = useState(false);
  const [exerciseDetails, setExerciseDetails] = useState(null);

  const handleSubmitOpen = (exerciseDetails) => {
    setExerciseDetails(exerciseDetails);
    setOpenSubmit(true);
  };

  const handleSubmitClose = () => {
    setOpenSubmit(false);
  };

  const handleInputCommentChange = (comment) => {
    setCommentDetails(comment);
  };

  const handleSubmitExercise = async (comment, submitDetails) => {
    if (comment) {
      await dispatch(
        addSubmitDetails({
          submitDetails,
          exerciseEditId: exerciseDetails?.exerciseEditId,
          type: "SUBMIT",
        })
      );
      await dispatch(
        getExerciseStatus({
          sessionId: params?.sessionId,
          offset,
          sortBy: sortBy,
          sortOrder: orderBy,
          bootcampIds: filterExercise?.bootcampIds?.id,
          bootcampId: bootcampId,
        })
      );
      setOpenSubmit(false);
    }
  };

  //////////////////// Review Modal ////////////////////////////
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isReviewMode, setIsReviewMode] = useState(false);
  const [isReopenTextField, setIsReopenTextField] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(false);
  const [reviewDetails, setReviewDetails] = useState({
    score: null,
    comment: null,
    maxExerciseScore: null,
  });

  const [initialExerciseDetails, setInitialExerciseDetails] = useState({
    score: null,
    comment: null,
    maxExerciseScore: null,
  });

  const submitReviewOpen = (exerciseDetails) => {
    setSelectedExercise(exerciseDetails);
    setReviewDetails(exerciseDetails);
    setIsReviewMode(true);
    setOpenReviewModal(true);
  };
  const submitReviewClose = () => {
    setReviewDetails((prev) => {
      return {
        ...prev,
        score: null,
        comment: null,
      };
    });
    setInitialExerciseDetails({
      score: null,
      comment: null,
      maxExerciseScore: null,
    });
    setIsEditMode(false);
    setIsReopenTextField(false);
    setIsReviewMode(false);
    setOpenReviewModal(false);
  };
  const handleInputReviewInput = (value, name) => {
    if (name === "score") {
      if ((value === "" || regex.test(value)) && Number(value) >= 0) {
        setReviewDetails((prev) => {
          return {
            ...prev,
            [name]: +value,
          };
        });
      }
    } else {
      setReviewDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const submitReview = async (submitDetails) => {
    if (
      (isReopenTextField && submitDetails?.json?.comment) ||
      (submitDetails?.json?.score && submitDetails?.json?.comment)
    ) {
      let apiValue = await dispatch(
        addSubmitDetails({
          submitDetails,
          exerciseEditId: selectedExercise?.exerciseId,
          type: isEditMode
            ? "EDIT_SCORE"
            : isReopenTextField
            ? "REOPEN"
            : "REVIEW",
        })
      );
      if (apiValue?.error?.message === "Rejected") {
      } else {
        await dispatch(
          getExerciseStatus({
            ...filterExercise,
            sessionId: params?.sessionId,
            offset,
            sortBy: sortBy,
            sortOrder: orderBy,
            bootcampIds: filterExercise?.bootcampIds?.id,
            bootcampId: bootcampId,
          })
        );
        setOpenReviewModal(false);
        setIsReviewMode(false);
        setIsEditMode(false);
        setIsReopenTextField(false);
      }
    }
  };

  const handleEditReviewModalOpen = (editDetails) => {
    setSelectedExercise(editDetails);
    setIsEditMode(true);
    setReviewDetails((prev) => {
      return {
        ...prev,
        score: +editDetails?.score,
        comment: editDetails?.comment,
        maxExerciseScore: editDetails?.maxExerciseScore,
      };
    });
    setInitialExerciseDetails({
      ...reviewDetails,
      score: +editDetails?.score,
      comment: editDetails?.comment,
    });
    setOpenReviewModal(true);
  };
  const handleReopenModalOpen = (reopenDetails) => {
    setSelectedExercise(reopenDetails);
    setIsReopenTextField(true);
    setOpenReviewModal(true);
  };

  /////////////////////////////////////////////////////////////////
  ////// filter Exercise tab ///////////////////////////////////////////////////////
  const handleFilterClose = () => {
    handleExerciseFilterClose();
  };
  const handleFilterExerciseSubmit = async (updatedFilterExercise) => {
    const isEvent = typeof updatedFilterExercise.preventDefault === "function";
    setOffset(0);
    setOffsetChangedByUser(false);
    setCurrentPage(1);
    setIsLoading(true);
    const filterCount = calculateFilterCount(
      isEvent ? filterExercise : updatedFilterExercise
    );
    const data = {
      ...filterExercise,
      sessionId: params?.sessionId,
      attendeeNameOrEmail: filterExercise?.attendeeNameOrEmail?.trim(),
      mentorNameOrEmail: filterExercise?.mentorNameOrEmail?.trim(),
      reviewerNameOrEmail: filterExercise?.reviewerNameOrEmail?.trim(),
      competencyIds: filterExercise?.competencyIds?.map((e) => e?.id),
      bootcampIds: filterExercise?.bootcampIds
        ? filterExercise?.bootcampIds?.id
        : null,
      batchIds: filterExercise?.batchIds?.map((e) => e?.id),
      sortBy: sortBy,
      sortOrder: orderBy,
      offset: 0,
      status: filterExercise?.status?.key ? filterExercise?.status?.key : null,
      bootcampId: bootcampId,
      showMentees: isEvent
        ? filterExercise?.showMentees
        : !!updatedFilterExercise?.showMentees,
      showReviewees: isEvent
        ? filterExercise?.showReviewees
        : !!updatedFilterExercise?.showReviewees,
      showAttendees: isEvent
        ? filterExercise?.showAttendees
        : !!updatedFilterExercise?.showAttendees,
    };
    if (filterCount) {
      await dispatch(getExerciseStatus(data));
      await dispatch(countExerciseFilter(filterCount));
    }
    handleExerciseFilterClose();
    setIsLoading(false);
  };
  const handleFilterExerciseRemove = async () => {
    setIsLoading(true);
    const bootcampIds = filterExercise?.bootcampIds;
    await setFilterExercise({
      ...initialFilterValues,
      ...(!(isHr || isAttendee)
        ? { bootcampIds: bootcampIds }
        : { bootcampIds: null }),
    });

    await dispatch(
      getExerciseStatus({
        sessionId: params?.sessionId,
        offset: 0,
        sortBy: sortBy,
        sortOrder: orderBy,
        ...(!(isHr || isAttendee) ? { bootcampIds: [bootcampIds?.id] } : null),
        ...(isMentor && !isSpoc && !isHr && { showMentees: true }),
        ...(isReviewer && !isSpoc && !isHr && { showReviewees: true }),
        ...(isSessionPresenter && !isSpoc && !isHr && { showAttendees: true }),
        bootcampId: bootcampId,
      })
    );
    await dispatch(
      countExerciseFilter(!isHr ? calculateFilterCount(initialFilterValues) : 0)
    );
    setCurrentPage(1);
    setOffset(0);
    setOffsetChangedByUser(false);
    handleExerciseFilterClose();
    setIsLoading(false);
  };

  const handleChangeFilterExercise = (name, value) => {
    setFilterExercise({ ...filterExercise, [name]: value });
  };
  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const fetchData = async () => {
      if (offsetChangedByUser) {
        if (!initialFilterApplied) {
          setIsLoading(true);
          const bootcampFilter =
            (await bootcamp?.filter((e) => e.id === +params?.bootcampId))[0] ||
            null;
          const updatedFilterExercise = {
            ...filterExercise,
            bootcampIds: bootcampFilter,
            ...(isMentor && !isSpoc && !isHr && { showMentees: true }),
            ...(isReviewer && !isSpoc && !isHr && { showReviewees: true }),
            ...(isSessionPresenter &&
              !isSpoc &&
              !isHr && { showAttendees: true }),
          };
          setInitialFilterValues(updatedFilterExercise);
          setFilterExercise(updatedFilterExercise);
          const filterCount = calculateFilterCount(updatedFilterExercise);
          await dispatch(
            getExerciseStatus({
              sessionId: params?.sessionId,
              offset,
              sortBy: sortBy,
              sortOrder: orderBy,
              ...(!isAttendee
                ? {
                    bootcampIds: [bootcampFilter?.id],
                  }
                : null),
              ...(isMentor && !isSpoc && !isHr && { showMentees: true }),
              ...(isReviewer && !isSpoc && !isHr && { showReviewees: true }),
              ...(isSessionPresenter &&
                !isSpoc &&
                !isHr && { showAttendees: true }),
              bootcampId: bootcampId,
            })
          );
          await dispatch(countExerciseFilter(filterCount));
          setIsLoading(false);
          setInitialFilterApplied(true);
        } else {
          await dispatch(
            getExerciseStatus({
              ...filterExercise,
              sessionId: params?.sessionId,
              offset,
              sortBy: sortBy,
              sortOrder: orderBy,
              attendeeNameOrEmail: filterExercise?.attendeeNameOrEmail?.trim(),
              mentorNameOrEmail: filterExercise?.mentorNameOrEmail?.trim(),
              reviewerNameOrEmail: filterExercise?.reviewerNameOrEmail?.trim(),
              batchIds: filterExercise?.batchIds?.map((e) => e?.id),
              status: filterExercise?.status?.key
                ? filterExercise?.status?.key
                : null,
              competencyIds: filterExercise?.competencyIds?.map((e) => e?.id),
              ...(!isAttendee
                ? {
                    bootcampIds: [filterExercise?.bootcampIds?.id],
                  }
                : null),
              ...(isMentor &&
                !isSpoc &&
                !isHr && { showMentees: filterExercise?.showMentees }),
              ...(isReviewer &&
                !isSpoc &&
                !isHr && { showReviewees: filterExercise?.showReviewees }),
              ...(isSessionPresenter &&
                !isSpoc &&
                !isHr && { showAttendees: filterExercise?.showAttendees }),
              bootcampId: bootcampId,
            })
          );
        }
      }
    };
    fetchData();
  }, [dispatch, sortBy, orderBy, offset, params?.sessionId]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  useEffect(() => {
    return () => {
      dispatch(clearExercise());
      setInitialFilterApplied(false);
      setFilterExercise(initialFilterExercise);
    };
  }, []);

  if ((loading && isLoading) || exerciseLoading) return <Loader />;

  return (
    <>
      {exerciseList?.length > 0 ? (
        <Box
          className="flex-container-column"
          sx={{ justifyContent: "space-between" }}
        >
          <TableContainer className="table-container">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="table-head-container">
                <TableRow className="table-head-row">
                  <TableCell
                    align="left"
                    sx={{ minWidth: "13rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Attendee</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "attendeeName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "attendeeName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "attendeeName")
                          }
                          disabled={
                            sortBy === "attendeeName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "attendeeName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "attendeeName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "attendeeName")
                          }
                          disabled={
                            sortBy === "attendeeName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "13rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Reviewer</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "reviewerName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "reviewerName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "reviewerName")
                          }
                          disabled={
                            sortBy === "reviewerName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "reviewerName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "reviewerName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "reviewerName")
                          }
                          disabled={
                            sortBy === "reviewerName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "13rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Mentor</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "leadMentorName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "leadMentorName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "leadMentorName")
                          }
                          disabled={
                            sortBy === "leadMentorName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "leadMentorName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "leadMentorName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "leadMentorName")
                          }
                          disabled={
                            sortBy === "leadMentorName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "13rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Co-Mentor</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "coMentorName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "coMentorName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "coMentorName")
                          }
                          disabled={
                            sortBy === "coMentorName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "coMentorName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "coMentorName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "coMentorName")
                          }
                          disabled={
                            sortBy === "coMentorName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "10rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Batch</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "competencyName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "competencyName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() => handleSorting("ASC", "batchName")}
                          disabled={sortBy === "batchName" && orderBy === "ASC"}
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "competencyName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "competencyName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() => handleSorting("DESC", "batchName")}
                          disabled={
                            sortBy === "batchName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  {isHr && (
                    <TableCell
                      align="left"
                      sx={{ minWidth: "13rem" }}
                      className="padding-left-1"
                    >
                      <Typography
                        variant="font_13_bold"
                        className="flex-table-sort-head"
                      >
                        <Box className="table-sort-head">Bootcamp</Box>
                        <Box className="table-sort-icon">
                          <IconButtons
                            height={font_9}
                            width={font_9}
                            image={up_sort_arrow_icon}
                            handleClick={() =>
                              handleSorting("ASC", "bootcampName")
                            }
                            disabled={
                              sortBy === "bootcampName" && orderBy === "ASC"
                            }
                            tooltip={sortInAscending}
                          />
                          <IconButtons
                            height={font_9}
                            width={font_9}
                            image={down_sort_arrow_icon}
                            handleClick={() =>
                              handleSorting("DESC", "bootcampName")
                            }
                            disabled={
                              sortBy === "bootcampName" && orderBy === "DESC"
                            }
                            tooltip={sortInDescending}
                          />
                        </Box>
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell
                    align="left"
                    sx={{ minWidth: "10rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Competency</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={font_9}
                          width={font_9}
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "competencyName")
                          }
                          disabled={
                            sortBy === "competencyName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={font_9}
                          width={font_9}
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "competencyName")
                          }
                          disabled={
                            sortBy === "competencyName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "8rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Status</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "status" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "status" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() => handleSorting("ASC", "status")}
                          disabled={sortBy === "status" && orderBy === "ASC"}
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "status" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "status" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() => handleSorting("DESC", "status")}
                          disabled={sortBy === "status" && orderBy === "DESC"}
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "6rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box>Score</Box>
                      <Box className="table-sort-head">
                        <BootstrapTooltip
                          title={`Max Score: ${maxExerciseScore}`}
                          placement="right"
                        >
                          <IconButton
                            sx={{
                              color: "rgb(0, 0, 0)",
                              padding: "0 0.125rem",
                              marginBottom: "0.125rem",
                            }}
                          >
                            <InfoIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </BootstrapTooltip>
                      </Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "score" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "score" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() => handleSorting("ASC", "score")}
                          disabled={sortBy === "score" && orderBy === "ASC"}
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "score" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "score" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() => handleSorting("DESC", "score")}
                          disabled={sortBy === "score" && orderBy === "DESC"}
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "10rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box>Submitted Date</Box>
                      <Box className="table-sort-head">
                        <BootstrapTooltip
                          title={`Due Date: ${exerciseDueDate}`}
                          placement="right"
                        >
                          <IconButton
                            sx={{
                              color: "rgb(0, 0, 0)",
                              padding: "0 0.125rem",
                              marginBottom: "0.125rem",
                            }}
                          >
                            <InfoIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </BootstrapTooltip>
                      </Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "dateSubmitted" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "dateSubmitted" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "dateSubmitted")
                          }
                          disabled={
                            sortBy === "dateSubmitted" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "dateSubmitted" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "dateSubmitted" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "dateSubmitted")
                          }
                          disabled={
                            sortBy === "dateSubmitted" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "10rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Reviewed Date</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "dateReviewed" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "dateReviewed" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "dateReviewed")
                          }
                          disabled={
                            sortBy === "dateReviewed" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "dateReviewed" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "dateReviewed" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "dateReviewed")
                          }
                          disabled={
                            sortBy === "dateReviewed" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  {canAccessActionItems && (
                    <TableCell
                      align="left"
                      sx={{ minWidth: "8rem" }}
                      className="padding-left-1"
                    >
                      <Typography variant="font_13_bold">Actions</Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {exerciseList?.map((exercise, index) => {
                  const attendee = exercise?.attendee?.attendee?.attendee;
                  const attendeeBatchName = exercise?.attendee?.batch?.name;
                  const traineeCompetency = !attendee?.competency?.name
                    ? "-"
                    : attendee?.competency?.name;
                  const traineeBootcamp = !exercise?.attendee?.attendee
                    ?.bootcamp?.name
                    ? "-"
                    : exercise?.attendee?.attendee?.bootcamp?.name;
                  const attendeeDeleted =
                    attendee?.deleted || exercise?.attendee?.deleted;
                  const userDropped =
                    exercise?.attendee?.status === DROPPED ||
                    exercise?.attendee?.attendee?.status === DROPPED;
                  const userSeperated =
                    exercise?.attendee?.status === SEPARATED ||
                    exercise?.attendee?.attendee?.status === SEPARATED;
                  const statusUpdatedDate =
                    exercise?.attendee?.statusUpdatedDate ||
                    exercise?.attendee?.attendee?.statusUpdatedDate;
                  const status =
                    exercise?.attendee?.status === DROPPED ||
                    exercise?.attendee?.status === SEPARATED
                      ? exercise?.attendee?.status
                      : exercise?.attendee?.attendee?.status === DROPPED ||
                        exercise?.attendee?.attendee?.status === SEPARATED
                      ? exercise?.attendee?.attendee?.status
                      : null;
                  const reviewer = exercise?.reviewer;

                  const exerciseStatus = exerciseStatusChip(exercise?.status);
                  const exerciseScore = !exercise?.score
                    ? null
                    : exercise?.score;
                  const exerciseSubmitDate = !exercise?.dateSubmitted
                    ? null
                    : dayjs(exercise?.dateSubmitted)?.format(
                        "DD/MM/YYYY hh:mmA"
                      );
                  const exerciseCompleteDate = !exercise?.dateReviewed
                    ? null
                    : dayjs(exercise?.dateReviewed)?.format(
                        "DD/MM/YYYY hh:mmA"
                      );

                  const moduleMentor = exercise?.attendee?.leadMentor;
                  const bootcampMentor =
                    exercise?.attendee?.attendee?.leadMentor;
                  const moduleCoMentor = exercise?.attendee?.coMentor;
                  const bootcampCoMentor =
                    exercise?.attendee?.attendee?.coMentor;

                  return (
                    <TableRow className="table-body-row" key={index}>
                      <TableCell className="padding-left-1">
                        <UserTableAvatar
                          imgAlt={attendee?.fullName}
                          imgSrc={attendee?.profilePictureUrl}
                          href={attendee?.employeeCode}
                          label={attendee?.fullName}
                          subLabel={attendee?.email}
                          deleted={
                            userSeperated || userDropped || attendeeDeleted
                          }
                          tooltip={
                            userDropped || userSeperated
                              ? NON_ACTIVE_ATTENDEE(
                                  getStatus(attendeeStatuses, status),
                                  dayjs(statusUpdatedDate)
                                    ?.tz(tz)
                                    ?.format("DD MMM YYYY")
                                )
                              : null
                          }
                        />
                      </TableCell>
                      <TableCell className="padding-left-1">
                        {!!reviewer ? (
                          <UserTableAvatar
                            imgAlt={reviewer?.fullName}
                            imgSrc={reviewer?.profilePictureUrl}
                            href={reviewer?.employeeCode}
                            label={reviewer?.fullName}
                            subLabel={reviewer?.email}
                            deleted={reviewer?.deleted}
                          />
                        ) : (
                          <Typography variant="font-12">-</Typography>
                        )}
                      </TableCell>
                      <TableCell className="padding-left-1">
                        <UserModuleBootcamp
                          module={moduleMentor}
                          moduleId={moduleId}
                          bootcamp={bootcampMentor}
                        />
                      </TableCell>
                      <TableCell className="padding-left-1">
                        <UserModuleBootcamp
                          module={moduleCoMentor}
                          moduleId={moduleId}
                          bootcamp={bootcampCoMentor}
                        />
                      </TableCell>
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">
                          {attendeeBatchName}
                        </Typography>
                      </TableCell>
                      {isHr && (
                        <TableCell align="left" className="padding-left-1">
                          <BootstrapTooltip title="View Bootcamp">
                            <Typography variant="font_12" color="secondary">
                              <Link
                                to={`/`}
                                state={{
                                  propsToPass: {
                                    bootcampName: traineeBootcamp,
                                    bootcampId:
                                      exercise?.attendee?.attendee?.bootcamp
                                        ?.id,
                                  },
                                }}
                              >
                                <u>{traineeBootcamp}</u>
                              </Link>
                            </Typography>
                          </BootstrapTooltip>
                        </TableCell>
                      )}
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">
                          {traineeCompetency}
                        </Typography>
                      </TableCell>

                      <TableCell align="left" className="padding-left-1">
                        <Chip
                          sx={{
                            width: "4.6rem",
                            "& .MuiChip-label": { padding: "0 0.656rem" },
                          }}
                          label={exerciseStatus?.name}
                          variant={exerciseStatus?.theme}
                        />
                      </TableCell>
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">
                          {exerciseScore}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">
                          {exerciseSubmitDate}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">
                          {exerciseCompleteDate}
                        </Typography>
                      </TableCell>
                      {canAccessActionItems && (
                        <TableCell align="left" className="padding-left-1">
                          <Box className="table-action-flex">
                            <ExerciseStatusActions
                              handleCommentOpen={handleCommentOpen}
                              isModuleOngoing={isModuleOngoing}
                              attendeeDeleted={attendeeDeleted}
                              userSeperated={userSeperated}
                              userDropped={userDropped}
                              isHr={isHr}
                              exercise={exercise}
                              permissions={permissions}
                              handleEditExerciseTabOpen={
                                handleEditExerciseTabOpen
                              }
                              handleSubmitOpen={() =>
                                handleSubmitOpen({
                                  exerciseEditId: exercise?.id,
                                  score: +exercise?.score,
                                })
                              }
                              exerciseStatus={exercise?.status}
                              submitReviewOpen={() =>
                                submitReviewOpen({
                                  exerciseId: exercise?.id,
                                  maxExerciseScore,
                                })
                              }
                              isBootcampCompleted={isBootcampCompleted}
                              handleEditReviewModalOpen={() =>
                                handleEditReviewModalOpen({
                                  exerciseId: exercise?.id,
                                  score: +exercise?.score,
                                  maxExerciseScore,
                                })
                              }
                              handleReopenModalOpen={() =>
                                handleReopenModalOpen({
                                  exerciseId: exercise?.id,
                                })
                              }
                            />
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container className="table-pagination-container">
            <Typography variant="font_16">
              Total {totalResult} Records
            </Typography>
            <TablePagination
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
            />
          </Grid>
        </Box>
      ) : (
        <Grid container className="no-data-container tabs-no-data">
          <NoDataAvailable
            imgHeight={4}
            imgWidth={4.5}
            message="No Exercises Available"
          />
        </Grid>
      )}
      <CommentModal
        open={openComment}
        handleClose={handleCommentClose}
        handleInputComment={handleInputComment}
        commentDetails={commentDetails}
        handleSubmit={(comment) => handleSubmit(comment)}
        comments={comments}
        exerciseId={exerciseId}
        openComment={setOpenComment}
        canAddComment={canAddComment}
        canEditDeleteComments={isSpoc && canAddComment}
        isBootcampCompleted={isBootcampCompleted}
      />

      <ExerciseTabFilter
        open={filterOpen}
        onClose={handleFilterClose}
        handleFilterExerciseSubmit={handleFilterExerciseSubmit}
        handleFilterExerciseRemove={handleFilterExerciseRemove}
        handleChangeFilterExercise={handleChangeFilterExercise}
        filterExercise={filterExercise}
        competency={competency}
        bootcamp={bootcampList}
        batches={batches}
        apiLoading={apiLoading}
        isHr={isHr}
        permissions={permissions}
      />
      <ReviewerEdit
        open={openReviewerEdit}
        handleClose={handleEditExerciseTabClose}
        handleEditReviewerChange={handleReviewerChange}
        competencyList={competencyList}
        editReviewerDetails={editReviewerDetails}
        handleSubmit={handleSubmitEditExercise}
        loader={editReviewerLoading}
        apiError={editReviewerApiError}
      />
      <SubmitModal
        open={openSubmit}
        exerciseDetails={exerciseDetails}
        commentDetails={commentDetails}
        handleInputCommentChange={handleInputCommentChange}
        handleClose={handleSubmitClose}
        handleSubmit={(comment, submitDetails) =>
          handleSubmitExercise(comment, submitDetails)
        }
        loader={addSubmitDetailsLoading}
      />
      <ReviewModal
        open={openReviewModal}
        initialExerciseDetails={initialExerciseDetails}
        reviewDetails={reviewDetails}
        handleInputReviewInput={handleInputReviewInput}
        handleClose={submitReviewClose}
        handleSubmitReview={(comment, submitDetails) =>
          submitReview(comment, submitDetails)
        }
        isEditMode={isEditMode}
        isReopenTextField={isReopenTextField}
        isReviewMode={isReviewMode}
      />
    </>
  );
};

export default ExerciseTab;

import React, { useEffect, useRef, useState } from "react";
import "./evaluationListing.scss";
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearUserData } from "../../store/users";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  clearOptions,
  createEvaluation,
  getAssessmentByIdOnEvaluation,
  getBootcampById,
  getOptionsForEditEvaluationInterviewer,
  sendEvaluationReminder,
  setEvaluationListingData,
  updateAttendee,
} from "../../store/evaluationListing";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import { apiBasePath, HelpTexts } from "../../constants/constants";
import EvaluationListingTable from "../../components/EvaluationListingTable/EvaluationListingTable";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import EditEvaluationInterviewer from "./EditEvaluationInterviewer";
import { evaluationStatusChip, font_13_icon, ROLE_TYPES } from "../../utils/utils";
import IconButtons from "../../components/IconButtons/IconButtons";
import { download_active_icon, download_disabled_icon } from "../../constants/icons";
import { backendBaseUrl } from "../../config/config";

const { INTERVIEW, MCQ } = HelpTexts;

const EvaluationListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const security = useSelector((state) => state?.security);
  const userCompetency = security?.loggedInUser?.competency;
  const isHr = ROLE_TYPES?.isHr(security?.roles);
  const { viaAssessmentView } = location?.state || {};
  const evaluationData = useSelector((state) => state?.evaluationListing);
  const evaluationDataLoading = evaluationData?.evaluationListingLoader;
  const bootcampDataLoading = evaluationData?.bootcampDataLoader;
  const assessmentDataLoading = evaluationData?.getAssessmentLoader;
  const sendEvaluationReminderLoader =
    evaluationData?.sendEvaluationReminderLoading;
  const updateAttendeeLoader = evaluationData?.updateAttendeeLoading;
  const userLoading = evaluationData?.userLoading;
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (value) => {
    setOffset((value - 1) * 20);
    setCurrentPage(value);
  };
  const evaluationListing =
    evaluationData?.evaluationListingData?.data?.results;
  const totalResult = evaluationData?.evaluationListingData?.data?.totalResults;
  const assessmentData = evaluationData?.assessmentData?.data;
  const assessmentDataPermissions = assessmentData?.permission || {};
  const {
    canViewCreateEvaluations,
    canCreateEvaluations,
    canViewSendReminder,
    canSendReminder,
    createEvaluationsMessage,
    sendReminderMessage,
  } = assessmentDataPermissions;
  const bootcampData = evaluationData?.bootcampData?.data;
  const module = assessmentData?.module;
  //////////////////////////////////////////////////////////////////////
  const [sortBy, setSortBy] = useState("attendeeName");
  const [orderBy, setOrderBy] = useState("ASC");

  const handleSorting = (order, sort) => {
    setSortBy(sort);
    setOrderBy(order);
  };

  const params = useParams();
  const { moduleId, bootcampId, assessmentId } = params;
  const assessmentType = assessmentData?.type;
  const isTypeInterview = assessmentType === INTERVIEW ? true : false;

  const downloadFile = (data) => {
    window.open(`${backendBaseUrl}${apiBasePath}/assessment/${data?.assessmentId}/report`, "_blank");
  };

  const handleReportDownload = (e, assessmentId) => {
    if (!isHr) {
      downloadFile({ assessmentId });
    }
  }

  const handleReopenEvaluationModal = (evaluationId) => {
    console.log("reopen", evaluationId);
  };

  const handleViewEvaluation = (evaluationId) => {
    navigate(
      moduleId
        ? `/bootcamp/${bootcampId}/module/${moduleId}/assessment/${assessmentId}/evaluations/${evaluationId}`
        : `/bootcamp/${bootcampId}/assessment/${assessmentId}/evaluations/${evaluationId}`,
      { state: { evaluationId } }
    );
  };

  const handleCreateEvaluationOpen = async () => {
    setIsLoading(true);
    const apiValue = await dispatch(createEvaluation(assessmentId));
    if (apiValue?.error?.message === "Rejected") {
      return;
    } else {
      await dispatch(
        setEvaluationListingData({
          assessmentId,
          bootcampId,
          moduleId,
          sortBy,
          sortOrder: orderBy,
          offset,
          type: assessmentType,
          bootcampId,
        })
      );
    }
    setIsLoading(false);
  };

  ////////////
  const handleSendEvaluationReminder = async () => {
    await dispatch(sendEvaluationReminder({ assessmentId }));
  };

  ////////////
  const [filterBy, setFilterBy] = useState("");
  const inputRef = useRef(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleFilterChange = (value) => {
    setFilterBy(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      dispatch(
        setEvaluationListingData({
          assessmentId,
          textSearch: value,
          bootcampId,
        })
      ).then(() => {
        setTimeout(() => {
          inputRef?.current?.focus();
        }, 1000);
      });
    }, 1000);
    setTimeoutId(newTimeoutId);
  };

  //////////////////////////////////////////////////////////////////
  //////////////////// validate trainee /////////////////////////
  const initialTraineeErrorState = {
    evaluationId: false,
    bootcampId: false,
    leadInterviewerId: false,
    coInterviewerId: false,
    status: false,
  };
  const [traineeErrors, setTraineeErrors] = useState(initialTraineeErrorState);

  const validateEditTrainee = (traineeDetails) => {
    const { leadInterviewerId } = traineeDetails;
    const newErrors = {
      leadInterviewerId: leadInterviewerId
        ? ""
        : "Lead Interviewer is required",
    };
    setTraineeErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  ///////////////////////////////////////////////////////////////////////
  ////////////////////////  Edit Attendee ////////////////////////////////////

  const initialEditEvaluationInterviewerDetails = {
    evaluationId: null,
    leadInterviewerId: null,
    coInterviewerId: null,
    status: "",
    interviewerCompetencyId: null,
    interviewerDesignationId: null,
    coInterviewerCompetencyId: null,
    coInterviewerDesignationId: null,
  };

  const [editEvaluationInterviewerOpen, setEditEvaluationInterviewerOpen] =
    useState(false);
  const [
    editEvaluationInterviewerDetails,
    setEditEvaluationInterviewerDetails,
  ] = useState(initialEditEvaluationInterviewerDetails);
  const [isEditEvaluationInterviewer, setIsEditEvaluationInterviewer] =
    useState(false);
  const [previousDetails, setPreviousDetails] = useState(
    initialEditEvaluationInterviewerDetails
  );
  const handleEditEvaluationOpen = (data) => {
    setIsEditEvaluationInterviewer(true);
    if (
      !evaluationData?.optionsForEditEvaluationInterviewer?.data?.competencies
        ?.results
    ) {
      dispatch(
        getOptionsForEditEvaluationInterviewer({
          assessmentId: assessmentId,
        })
      );
    }
    const leadInterviewer = data?.leadInterviewer;
    const coInterviewer = data?.coInterviewer;
    setEditEvaluationInterviewerDetails({
      ...editEvaluationInterviewerDetails,
      evaluationId: data?.id,
      leadInterviewerId: leadInterviewer?.deleted ? null : leadInterviewer,
      coInterviewerId: coInterviewer?.deleted ? null : coInterviewer,
      status: data?.status,
    });
    setPreviousDetails((prevDetails) => ({
      ...prevDetails,
      leadInterviewerId: leadInterviewer?.deleted ? null : leadInterviewer,
      coInterviewerId: coInterviewer?.deleted ? null : coInterviewer,
    }));
    setEditEvaluationInterviewerOpen(true);
  };
  const handleEditInterviewerClose = () => {
    setEditEvaluationInterviewerOpen(false);
    setTraineeErrors(initialTraineeErrorState);
    setEditEvaluationInterviewerDetails(
      initialEditEvaluationInterviewerDetails
    );
    setPreviousDetails(initialEditEvaluationInterviewerDetails);
    setIsEditEvaluationInterviewer(false);
  };
  const handleEditTraineeChange = (name, value) => {
    setTraineeErrors((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
    const updatedFilters = {
      ...editEvaluationInterviewerDetails,
      [name]: value,
    };
    if (name === "interviewerCompetencyId" && !value) {
      updatedFilters.leadInterviewerId = null;
    } else if (name === "coInterviewerCompetencyId" && !value) {
      updatedFilters.coInterviewerId = null;
      updatedFilters.coInterviewerDesignationId = null;
    }
    setEditEvaluationInterviewerDetails(updatedFilters);
  };
  const handleEditTraineeSubmit = async () => {
    const data = {
      evaluationId: editEvaluationInterviewerDetails?.evaluationId,
      leadInterviewerId:
        editEvaluationInterviewerDetails?.leadInterviewerId?.id,
      coInterviewerId: editEvaluationInterviewerDetails?.coInterviewerId?.id,
      status: editEvaluationInterviewerDetails?.status,
    };

    if (validateEditTrainee(data)) {
      await dispatch(updateAttendee(data));
      await dispatch(
        setEvaluationListingData({
          assessmentId,
          bootcampId,
          moduleId,
          sortBy,
          sortOrder: orderBy,
          offset,
          type: assessmentType,
          bootcampId,
        })
      );
      setEditEvaluationInterviewerOpen(false);
      // setAddAttendeeDetails(initialAddAttendee);
      dispatch(clearOptions());
    }
  };

  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (
      editEvaluationInterviewerOpen &&
      evaluationData?.optionsForEditEvaluationInterviewer?.data?.competencies
        ?.results
    ) {
      const defaultCompetency =
        evaluationData?.optionsForEditEvaluationInterviewer?.data?.competencies?.results?.find(
          (it) => it?.id === bootcampData?.competency?.id
        );
      const interviewerCompetency =
        editEvaluationInterviewerDetails?.leadInterviewerId
          ? evaluationData?.optionsForEditEvaluationInterviewer?.data?.competencies?.results?.find(
              (it) =>
                it?.id ===
                editEvaluationInterviewerDetails?.leadInterviewerId?.competency
                  ?.id
            )
          : defaultCompetency;
      const interviewerDesignation =
        editEvaluationInterviewerDetails?.leadInterviewerId
          ? interviewerCompetency?.designations?.results?.find(
              (it) =>
                it?.id ===
                editEvaluationInterviewerDetails?.leadInterviewerId?.designation
                  ?.id
            )
          : null;

      const coInterviewerCompetency =
        editEvaluationInterviewerDetails?.coInterviewerId
          ? evaluationData?.optionsForEditEvaluationInterviewer?.data?.competencies?.results?.find(
              (it) =>
                it?.id ===
                editEvaluationInterviewerDetails?.coInterviewerId?.competency
                  ?.id
            )
          : defaultCompetency;
      const coInterviewerDesignation =
        editEvaluationInterviewerDetails?.coInterviewerId
          ? coInterviewerCompetency?.designations?.results?.find(
              (it) =>
                it?.id ===
                editEvaluationInterviewerDetails?.coInterviewerId?.designation
                  ?.id
            )
          : null;

      const data = {
        interviewerCompetencyId: interviewerCompetency,
        interviewerDesignationId: interviewerDesignation,
        coInterviewerCompetencyId: coInterviewerCompetency,
        coInterviewerDesignationId: coInterviewerDesignation,
      };
      setEditEvaluationInterviewerDetails({
        ...editEvaluationInterviewerDetails,
        ...data,
      });
      setPreviousDetails({
        ...previousDetails,
        ...data,
      });
    }
  }, [
    editEvaluationInterviewerOpen,
    evaluationData?.optionsForEditEvaluationInterviewer?.data?.competencies
      ?.results,
  ]);

  useEffect(() => {
    const competency =
      editEvaluationInterviewerDetails?.interviewerCompetencyId?.id;
    const previousCompetency = previousDetails?.interviewerCompetencyId?.id;
    if (competency && previousCompetency) {
      if (competency === previousCompetency) {
        setEditEvaluationInterviewerDetails({
          ...editEvaluationInterviewerDetails,
          interviewerDesignationId: previousDetails?.interviewerDesignationId,
          leadInterviewerId: previousDetails?.leadInterviewerId,
        });
      }
      if (competency !== previousCompetency) {
        setEditEvaluationInterviewerDetails({
          ...editEvaluationInterviewerDetails,
          interviewerDesignationId: null,
          leadInterviewerId: null,
        });
      }
    }
  }, [editEvaluationInterviewerDetails?.interviewerCompetencyId]);

  useEffect(() => {
    const designation =
      editEvaluationInterviewerDetails?.interviewerDesignationId?.id;
    const previousDesignation = previousDetails?.interviewerDesignationId?.id;
    if (designation === previousDesignation && !!previousDesignation) {
      setEditEvaluationInterviewerDetails({
        ...editEvaluationInterviewerDetails,
        leadInterviewerId: previousDetails?.leadInterviewerId,
      });
    }
    if (designation !== previousDesignation && !!designation) {
      setEditEvaluationInterviewerDetails({
        ...editEvaluationInterviewerDetails,
        leadInterviewerId: null,
      });
    }
  }, [editEvaluationInterviewerDetails?.interviewerDesignationId]);

  useEffect(() => {
    const designation =
      editEvaluationInterviewerDetails?.coInterviewerDesignationId?.id;
    const previousDesignation = previousDetails?.coInterviewerDesignationId?.id;

    if (designation === previousDesignation && !!previousDesignation) {
      setEditEvaluationInterviewerDetails({
        ...editEvaluationInterviewerDetails,
        coInterviewerId: previousDetails?.coInterviewerId,
      });
    }
    if (designation !== previousDesignation && !!designation) {
      setEditEvaluationInterviewerDetails({
        ...editEvaluationInterviewerDetails,
        coInterviewerId: null,
      });
    }
  }, [editEvaluationInterviewerDetails?.coInterviewerDesignationId]);

  useEffect(() => {
    const competency =
      editEvaluationInterviewerDetails?.coInterviewerCompetencyId?.id;
    const previousCompetency = previousDetails?.coInterviewerCompetencyId?.id;
    if (competency && previousCompetency) {
      if (competency === previousCompetency) {
        setEditEvaluationInterviewerDetails({
          ...editEvaluationInterviewerDetails,
          coInterviewerDesignationId:
            previousDetails?.coInterviewerDesignationId,
          coInterviewerId: previousDetails?.coInterviewerId,
        });
      }
      if (competency !== previousCompetency) {
        setEditEvaluationInterviewerDetails({
          ...editEvaluationInterviewerDetails,
          coInterviewerDesignationId: null,
          coInterviewerId: null,
        });
      }
    }
  }, [editEvaluationInterviewerDetails?.coInterviewerCompetencyId]);

  ///////////
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getBootcampById({ bootcampId }));
      await dispatch(getAssessmentByIdOnEvaluation(assessmentId));
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchEvaluationList = async () => {
      setIsLoading(true);
      if (assessmentType) {
        await dispatch(
          setEvaluationListingData({
            assessmentId,
            bootcampId,
            moduleId,
            sortBy,
            sortOrder: orderBy,
            offset,
            type: assessmentType,
            bootcampId,
          })
        );
      }
      setIsLoading(false);
    };
    fetchEvaluationList();
  }, [dispatch, sortBy, orderBy, offset, assessmentType]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  useEffect(() => {
    return () => {
      dispatch(clearUserData());
    };
  }, []);

  //------------------------------ Breadcrumbs ------------------------------//
  const breadcrumbs = moduleId
    ? [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: module?.name ? module?.name : "Module",
          url: `/bootcamp/${bootcampId}/module/${module?.id}` || "/",
        },
        {
          label: "Assessments",
          url: `/bootcamp/${bootcampId}/module/${module?.id}/assessments`,
        },
        ...(viaAssessmentView
          ? [
              {
                label: assessmentData?.title,
                url: `/bootcamp/${bootcampId}/module/${module?.id}/assessment/${assessmentId}`,
              },
            ]
          : []),
        {
          label: "Evaluations",
        },
      ]
    : [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: "Assessments",
          url: `/bootcamp/${bootcampId}/assessments`,
        },
        ...(viaAssessmentView
          ? [
              {
                label: assessmentData?.title,
                url: `/bootcamp/${bootcampId}/assessment/${assessmentId}`,
              },
            ]
          : []),
        {
          label: "Evaluations",
        },
      ];
  if (
    evaluationDataLoading ||
    isLoading ||
    bootcampDataLoading ||
    assessmentDataLoading ||
    sendEvaluationReminderLoader
  ) {
    return <Loader />;
  }

  const status = evaluationStatusChip(assessmentData?.status);

  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />

      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Box className="view-assessment-header">
            <Typography variant="font_21_bold" className="margin-right-2">
              {assessmentData?.title}
            </Typography>
            <Chip
              label={assessmentData?.type}
              variant="QUEST_TYPE"
              className="margin-right-2 module-chip-status"
            />
            <Chip
              className="same-size-chip"
              label={status?.name}
              variant={status?.theme}
            />
          </Box>
          <Box className="header-right-icons">
            {canViewCreateEvaluations && (
              <IconButtons
                size="small"
                height={font_13_icon}
                width={font_13_icon}
                image={download_active_icon}
                classList="margin-right-1"
                disabledImage={download_disabled_icon}
                handleClick={(event) => handleReportDownload(event, assessmentId)}
                tooltip="Download Assessment Report"
              />
            )}
            <TextField
              size="small"
              placeholder="Search"
              color="secondary"
              value={filterBy}
              onChange={(e) => handleFilterChange(e?.target?.value)}
              className="margin-right-1 evaluation-search-input"
              type="search"
              inputRef={inputRef}
            />
            {canViewSendReminder && (
              <BootstrapTooltip
                title={!canSendReminder ? sendReminderMessage : null}
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="action-head-btn margin-right-1"
                    onClick={handleSendEvaluationReminder}
                    disabled={!canSendReminder}
                  >
                    <Typography variant="outlineBtnLabel">
                      send reminder
                    </Typography>
                  </Button>
                </Box>
              </BootstrapTooltip>
            )}
            {canViewCreateEvaluations && (
              <BootstrapTooltip
                title={!canCreateEvaluations ? createEvaluationsMessage : null}
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCreateEvaluationOpen}
                    sx={{ height: "1.875rem" }}
                    disabled={!canCreateEvaluations}
                  >
                    <Typography variant="outlineBtnLabel">
                      Create Evaluations
                    </Typography>
                  </Button>
                </Box>
              </BootstrapTooltip>
            )}{" "}
          </Box>
        </Grid>
        <Grid item md={12} className="body-container">
          <Grid container className="table-parent-container">
            <Paper
              elevation={0}
              className="flex-container-column user-paper-container"
            >
              {!evaluationDataLoading && !evaluationListing?.length ? (
                <Grid container className="no-data-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Evaluations Found"
                  />
                </Grid>
              ) : (
                evaluationListing?.length > 0 && (
                  <EvaluationListingTable
                    isTypeInterview={isTypeInterview}
                    evaluationListing={evaluationListing}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    handleSorting={handleSorting}
                    sortBy={sortBy}
                    orderBy={orderBy}
                    viaModule={moduleId}
                    totalResult={totalResult}
                    handleEditEvaluation={handleEditEvaluationOpen}
                    handleReopenEvaluationModal={handleReopenEvaluationModal}
                    handleViewEvaluation={handleViewEvaluation}
                    isHr={isHr}
                  />
                )
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <EditEvaluationInterviewer
        title="Edit Interviewer"
        open={editEvaluationInterviewerOpen}
        handleClose={handleEditInterviewerClose}
        editEvaluationInterviewerDetails={editEvaluationInterviewerDetails}
        handleEditTraineeChange={handleEditTraineeChange}
        handleSubmit={handleEditTraineeSubmit}
        loader={updateAttendeeLoader}
        errors={traineeErrors}
        competencyList={
          evaluationData?.optionsForEditEvaluationInterviewer?.data
            ?.competencies?.results
        }
        userCompetency={userCompetency}
        userLoading={userLoading}
        isEditEvaluationInterviewer={isEditEvaluationInterviewer}
      />
    </>
  );
};

export default EvaluationListing;

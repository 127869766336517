import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import {
  circle_save_change_icon,
  cross_default_icon,
  cross_red_icon,
  edit_active_icon,
} from "../../constants/icons";
import AutoCompleteTagInput from "../../components/AutoCompleteTagInput/AutoCompleteTagInput";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import {
  ROLE_TYPES,
  allValuesDisabled,
  checkSpecialChar,
  font_13_icon,
  shouldDisableWeekends,
  todayDate,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import DateTimePickerComponent from "../../components/DateTimePickerComponent/DateTimePickerComponent";
import dayjs from "dayjs";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import Loader from "../../components/Loader/Loader";
import IconButtons from "../../components/IconButtons/IconButtons";

const compareFormFields = (initial, current) => {
  const fieldsToCheck = [
    "competencyId",
    "name",
    "startDate",
    "spocIds",
    "moduleIds",
  ];

  return fieldsToCheck.some((field) => {
    if (field === "spocIds" || field === "moduleIds") {
      if (initial[field].length !== current[field].length) return true;
      const initialIds = initial[field].map((item) => item.id).sort();
      const currentIds = current[field].map((item) => item.id).sort();
      return !initialIds.every((id, index) => id === currentIds[index]);
    } else if (field === "startDate") {
      return !dayjs(initial[field]).isSame(dayjs(current[field]));
    } else if (field === "name") {
      return initial[field].trim() !== current[field].trim();
    }
    return initial[field] !== current[field];
  });
};

const CreateBootcamp = ({
  open,
  onClose,
  bootcampDetails,
  getBootcampData,
  handleBootcampDetailsChange,
  handleSubmitBootcamp,
  isEdit,
  bootcampApiError,
  bootcampSpeChar,
  editName,
  handleEditNameToggle,
  handleEditNameCancel,
  bootcampNameLengthError,
  loader,
  moduleList,
  handleEditNameAccept,
  disabledModulesId,
}) => {
  const initialErrorState = {
    name: "",
    startDate: "",
    competencyId: "",
    spocIds: "",
    startDateModule: "",
    moduleIds: "",
  };

  const modalApiLoad = useSelector((state) => {
    return state?.bootcamp;
  });
  const modalApiLoading = modalApiLoad?.ModalApiLoading;
  const moduleApiLoad = modalApiLoad?.moduleLoading;

  const [errors, setErrors] = useState(initialErrorState);
  const security = useSelector((state) => state?.security);
  const roles = security?.roles;
  const userId = security?.loggedInUser?.id;
  const isHr = ROLE_TYPES?.isHr(roles);

  const validateFields = () => {
    const { name, startDate, competencyId, spocIds, moduleIds } =
      bootcampDetails;
    const newErrors = {
      name: editName
        ? "Bootcamp name is not saved"
        : name
        ? name?.trim()?.length <= 60
          ? ""
          : "Maximum 60 characters allowed"
        : "Name is required",
      startDate: startDate ? "" : "Start Date is required",
      competencyId: isHr && !competencyId ? "Competency is required" : "",
      spocIds: spocIds && spocIds.length > 0 ? "" : "SPOC are required",
      startDateModule: validateBootcampStartDate(startDate, moduleIds),
      moduleIds: validateOverlappingModules(moduleIds),
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const sortModules = (modules) => {
    let sortedModules = [...modules];
    return sortedModules.sort((module1, module2) => {
      const startDate1 = dayjs(module1?.startDate);
      const startDate2 = dayjs(module2?.startDate);
      if (startDate1.isValid() && startDate2.isValid()) {
        if (startDate1.isAfter(startDate2)) {
          return 1;
        } else if (startDate1.isBefore(startDate2)) {
          return -1;
        }
      }
      return 0;
    });
  };

  const validateOverlappingModules = (modules) => {
    let error = [];
    let moduleError = [];
    if (modules?.length) {
      const sortedModules = sortModules(modules);
      for (let i = 0; i < sortedModules?.length - 1; i++) {
        if (
          i > 0 &&
          !dayjs(sortedModules[i - 1]?.endDate).isBefore(
            sortedModules[i]?.startDate,
            "date"
          )
        ) {
          moduleError.push(sortedModules[i - 1]);
          moduleError.push(sortedModules[i]);
        }
        if (
          !dayjs(sortedModules[i]?.endDate).isBefore(
            sortedModules[i + 1]?.startDate,
            "date"
          )
        ) {
          moduleError.push(sortedModules[i]);
          moduleError.push(sortedModules[i + 1]);
        }
      }
    }
    if (moduleError?.length === 0) {
      error = "";
    } else {
      error = [`Following Overlapping Module/s are added:`];
      const uniqueModules = [
        ...new Map(moduleError.map((item) => [item["id"], item])).values(),
      ];
      uniqueModules.forEach((it) =>
        error.push(
          `${it?.name}, Start Date: ${dayjs(it?.startDate)
            .tz()
            .format("YYYY-MM-DD")}, End Date: ${dayjs(it?.endDate)
            .tz()
            .format("YYYY-MM-DD")}`
        )
      );
    }
    return error;
  };

  const validateBootcampStartDate = (date, modules) => {
    let error = [];
    if (dayjs(date).isValid() && modules?.length) {
      const sortedModules = sortModules(modules);
      sortedModules?.forEach((it2) => {
        if (dayjs(date).isAfter(dayjs(it2?.startDate), "date")) {
          error.push(
            `${it2?.name}, Start Date: ${dayjs(it2?.startDate)
              .tz()
              .format("YYYY-MM-DD")}, End Date: ${dayjs(it2?.endDate)
              .tz()
              .format("YYYY-MM-DD")}`
          );
        }
      });
    }
    if (!error?.length) {
      error = "";
    } else {
      error = [
        `Bootcamp Start Date Interferes with following Module/s:`,
        ...error,
      ];
    }
    return error;
  };
  const handleSubmit = () => {
    if (validateFields()) {
      handleSubmitBootcamp();
    }
  };

  const handleOnClose = () => {
    setErrors(initialErrorState);
    onClose();
  };

  // Handler function to delete the chip
  const handleChipDelete = (optionToDelete) => {
    const newModuleIds = bootcampDetails?.moduleIds?.filter(
      (option) => option?.id !== optionToDelete?.id
    );
    handleBootcampDetailsChange("moduleIds", newModuleIds);
  };

  const handleSpocChipDelete = (optionToDelete) => {
    const newSpocIds = bootcampDetails?.spocIds?.filter(
      (option) => option?.id !== optionToDelete?.id
    );
    handleBootcampDetailsChange("spocIds", newSpocIds);
  };

  const [initialDetails, setInitialDetails] = useState(bootcampDetails);

  useEffect(() => {
    setInitialDetails(bootcampDetails);
  }, [bootcampDetails.bootcampId]);

  const detailsChanged = compareFormFields(initialDetails, bootcampDetails);

  return (
    <>
      {(moduleApiLoad || modalApiLoading || loader) && <Loader />}
      <Dialog
        className="modal-drawer-container"
        open={open}
        onClose={handleOnClose}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            zIndex: 1300,
            maxWidth: "29.75rem",
            height: "auto",
          },
        }}
      >
        <DialogTitle className="dialog-title">
          {isEdit ? "Edit Bootcamp" : "Create Bootcamp"}
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Box className="width-100">
            {(errors?.startDateModule?.length > 0 ||
              errors?.moduleIds?.length > 0) && (
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontSize: "0.75rem",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {[...errors?.startDateModule, ...errors?.moduleIds]?.map(
                  (string, index) => (
                    <React.Fragment key={index}>
                      {string}
                      <br />
                    </React.Fragment>
                  )
                )}
              </Typography>
            )}
          </Box>
          <Box display="flex">
            <Box flexBasis="60%" mr={2}>
              <SimpleAutoComplete
                label="Competency"
                placeholder="Select Competency"
                options={getBootcampData?.competencies?.data?.results}
                onChange={(event, newValue) => {
                  handleBootcampDetailsChange("competencyId", newValue);
                  setErrors((prev) => {
                    return {
                      ...prev,
                      competencyId: "",
                    };
                  });
                }}
                getOptionLabel={(option) => option?.name}
                value={bootcampDetails?.competencyId}
                required={true}
                error={!!errors.competencyId || bootcampApiError}
                helperText={errors.competencyId}
                disableClearable={true}
                disabled={true}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id === bootcampDetails?.competencyId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
            </Box>

            <Box flexBasis="40%" marginLeft="auto">
              <DateTimePickerComponent
                label="Start Date"
                format="DD/MM/YYYY"
                onChange={(e) => {
                  handleBootcampDetailsChange("startDate", e);
                  setErrors((prev) => {
                    return {
                      ...prev,
                      startDate: "",
                      startDateModule: "",
                      moduleIds: "",
                    };
                  });
                }}
                value={bootcampDetails?.startDate}
                required={true}
                error={!!errors.startDate || bootcampApiError}
                helperText={errors.startDate}
                views={["year", "day"]}
                minDate={dayjs().startOf("day").add(1, "day")}
                disabled={
                  isEdit &&
                  !dayjs(bootcampDetails?.startDate).isAfter(dayjs(), "date")
                }
                disableHighlightToday={true}
                viewRenderers={{
                  hours: null,
                  minutes: null,
                  seconds: null,
                }}
                closeOnSelect={true}
                shouldDisableDate={shouldDisableWeekends}
              />
            </Box>
          </Box>
          <Box className="width-100">
            {editName ? (
              <Box className="bootcamp-modal-name">
                <TextField
                  label="Name"
                  color="secondary"
                  fullWidth
                  className="filter-inputs"
                  margin="dense"
                  variant="outlined"
                  size="small"
                  value={bootcampDetails?.name}
                  onChange={(e) => {
                    handleBootcampDetailsChange("name", e?.target?.value);
                  }}
                  required={true}
                  error={
                    !bootcampDetails?.name ||
                    !!errors?.name ||
                    bootcampApiError ||
                    bootcampSpeChar ||
                    bootcampNameLengthError
                  }
                  helperText={
                    bootcampDetails?.name
                      ? bootcampSpeChar
                        ? "Cannot contain special characters"
                        : bootcampNameLengthError
                        ? "Maximum 60 characters allowed"
                        : errors?.name
                      : "Name is required"
                  }
                  sx={{ marginRight: "1rem" }}
                />
                <IconButtons
                  tooltip="Save Name"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={circle_save_change_icon}
                  handleClick={() => {
                    if (bootcampDetails?.name && !bootcampSpeChar) {
                      handleEditNameAccept();
                      setErrors({ ...errors, name: "" });
                    }
                  }}
                  classList="padding-right-1"
                />
                <IconButtons
                  tooltip="Cancel Edit"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={cross_red_icon}
                  handleClick={() => {
                    handleEditNameCancel();
                    setErrors({ ...errors, name: "" });
                  }}
                />
              </Box>
            ) : (
              <Box
                className="bootcamp-modal-name"
                sx={{ marginTop: "1rem", marginBottom: "1rem" }}
              >
                <Typography>{bootcampDetails?.name}</Typography>
                <IconButtons
                  tooltip="Edit Name"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={edit_active_icon}
                  handleClick={handleEditNameToggle}
                />
              </Box>
            )}

            <AutoCompleteTagInput
              label="SPOC"
              placeholder={
                bootcampDetails?.spocIds?.length ? null : "Select SPOC"
              }
              disabled={bootcampDetails?.competencies !== undefined}
              options={
                getBootcampData?.getCreateEditBootcampOptions?.data?.spocs
                  ?.results
              }
              selectedValue={bootcampDetails?.spocIds}
              getOptionLabel={(option) =>
                `${option?.fullName || ""} ${option?.email || ""}`
              }
              filterOptions={(options, params) => {
                // <<<--- inject the Select All option
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                if (
                  getBootcampData?.getCreateEditBootcampOptions?.data?.spocs
                    ?.results.length === 0
                ) {
                  return [{ fullName: "No options", all: false }];
                }
                return [{ fullName: "Select All", all: true }, ...filtered];
              }}
              onChange={(event, newValue) => {
                const idCounts = newValue.reduce((counts, { id }) => {
                  counts[id] = (counts[id] || 0) + 1;
                  return counts;
                }, {});
                const uniqueNewValue = newValue.filter(({ id }) => {
                  return idCounts[id] === 1;
                });
                if (newValue.find((option) => option.all)) {
                  return handleBootcampDetailsChange(
                    "spocIds",
                    bootcampDetails?.spocIds?.length ===
                      getBootcampData?.getCreateEditBootcampOptions?.data?.spocs
                        ?.results?.length
                      ? []
                      : getBootcampData?.getCreateEditBootcampOptions?.data
                          ?.spocs?.results
                  );
                }
                handleBootcampDetailsChange("spocIds", uniqueNewValue);
                setErrors((prev) => {
                  return {
                    ...prev,
                    spocIds: "",
                  };
                });
              }}
              error={!!errors.spocIds || bootcampApiError}
              helperText={errors.spocIds}
              required={true}
              renderOption={(props, option, value) => {
                // Check if option is "No options" and render accordingly
                if (option.fullName === "No options") {
                  return (
                    <Box className="auto-complete-no-options">
                      <Box>{option?.fullName}</Box>
                    </Box>
                  );
                }
                const optionChecked =
                  (option.all &&
                    bootcampDetails?.spocIds?.length ===
                      getBootcampData?.getCreateEditBootcampOptions?.data?.spocs
                        ?.results?.length) ||
                  bootcampDetails?.spocIds?.find((e) => e?.id === option?.id) !=
                    null;
                const optionsDisabled =
                  bootcampDetails?.spocIds?.some((it) => it?.id === userId) &&
                  option?.id === userId;
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      optionsDisabled
                        ? "multi-tag-edit-list-selected-disabled"
                        : optionChecked
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box className="multi-tag-input-list-padding">
                      <Checkbox
                        className="auto-complete-checkbox-list"
                        size="small"
                        checked={optionChecked}
                        color="secondary"
                      />
                      <Box className="flex-options">
                        <Box>{`${option?.fullName}`}</Box>
                        {option?.fullName === "Select All" ? null : (
                          <Typography
                            variant="caption"
                            className="filter-title "
                          >
                            {`${option?.email}`}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </li>
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const showCrossIcon = userId !== option?.id;
                  return (
                    <Chip
                      {...getTagProps(index)}
                      key={index}
                      label={option?.fullName}
                      onDelete={
                        showCrossIcon
                          ? () => handleSpocChipDelete(option)
                          : undefined
                      }
                    />
                  );
                })
              }
              getOptionDisabled={(option) => option.id === userId}
              disableClearable={allValuesDisabled(bootcampDetails?.spocIds, [
                userId,
              ])}
            />

            <AutoCompleteTagInput
              label="Modules"
              placeholder={
                bootcampDetails?.moduleIds?.length ? null : "Select Module"
              }
              options={moduleList}
              selectedValue={bootcampDetails?.moduleIds}
              disabled={!bootcampDetails?.startDate}
              getOptionLabel={(option) => option?.name}
              filterOptions={(options, params) => {
                // <<<--- inject the Select All option
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                if (moduleList.length === 0) {
                  return [{ name: "No options", all: false }];
                }

                return [
                  {
                    name: "Select All",
                    all: true,
                  },
                  ...filtered,
                ];
              }}
              onChange={(event, newValue) => {
                const idCounts = newValue.reduce((counts, { id }) => {
                  counts[id] = (counts[id] || 0) + 1;
                  return counts;
                }, {});
                const uniqueNewValue = newValue.filter(({ id }) => {
                  return idCounts[id] === 1;
                });
                if (newValue.find((option) => option.all)) {
                  return handleBootcampDetailsChange(
                    "moduleIds",
                    bootcampDetails?.moduleIds?.length === moduleList?.length
                      ? []
                      : moduleList
                  );
                }
                if (isEdit && event?.keyCode === 8) {
                  const lastItem =
                    bootcampDetails?.moduleIds[
                      bootcampDetails?.moduleIds?.length - 1
                    ];
                  if (disabledModulesId?.includes(lastItem?.id)) {
                    return;
                  }
                }

                handleBootcampDetailsChange("moduleIds", uniqueNewValue);
                setErrors((prev) => {
                  return {
                    ...prev,
                    startDateModule: "",
                    moduleIds: "",
                  };
                });
              }}
              renderOption={(props, option, value) => {
                // Check if option is "No options" and render accordingly
                if (option.name === "No options") {
                  return (
                    <Box className="auto-complete-no-options">
                      <Box>{option?.name}</Box>
                    </Box>
                  );
                }

                const optionChecked =
                  (option.all &&
                    bootcampDetails?.moduleIds?.length ===
                      moduleList?.length) ||
                  bootcampDetails?.moduleIds?.find(
                    (e) => e?.id === option?.id
                  ) != null;
                const optionsDisabled =
                  isEdit && disabledModulesId?.includes(option?.id);

                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      optionsDisabled
                        ? "multi-tag-edit-list-selected-disabled"
                        : optionChecked
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Checkbox
                      className="auto-complete-checkbox-list"
                      size="small"
                      checked={optionChecked}
                      color="secondary"
                    />
                    <Box className="flex-options">
                      <Box>{option?.name}</Box>
                      {option?.name === "Select All" ? null : (
                        <Box>
                          {dayjs(option?.startDate)?.format("DD/MM/YYYY")}
                          {option?.endDate && (
                            <>
                              {" - "}
                              {dayjs(option?.endDate)?.format("DD/MM/YYYY")}
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  </li>
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const showCrossIcon = !(
                    isEdit && disabledModulesId?.includes(option?.id)
                  );
                  return (
                    <Chip
                      {...getTagProps(index)}
                      key={index}
                      label={option?.name}
                      onDelete={
                        showCrossIcon || !isEdit
                          ? () => handleChipDelete(option)
                          : undefined
                      }
                    />
                  );
                })
              }
              getOptionDisabled={(option) =>
                isEdit && disabledModulesId?.includes(option?.id)
              }
              disableClearable={
                isEdit &&
                allValuesDisabled(bootcampDetails?.moduleIds, disabledModulesId)
              }
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleOnClose}
            className="block-button"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            className="block-button"
            disabled={isEdit && !detailsChanged}
          >
            <Typography variant="outlineBtnLabel">
              {isEdit ? "Update" : "Save"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateBootcamp;

import React, { useState } from "react";
import {
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import TablePagination from "../../components/TablePagination/TablePagination";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  delete_active_icon,
  delete_disabled_icon,
  disabled_msg_icon,
  edit_active_icon,
  edit_disabled_icon,
  message_active_icon,
  plus_dashed_active_icon,
  plus_dashed_default_icon,
} from "../../constants/icons";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import humanizeDuration from "humanize-duration";
import {
  checkDateTimeStatus,
  font_13_icon,
  font_21,
  ROLE_TYPES,
} from "../../utils/utils";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AttendanceLegends,
  ExerciseStatusLegends,
} from "../../components/LegendItem/LegendItem";
import SimpleModal from "../../components/Table/SimpleModal";
import Constants, { EventStatus, HelpTexts } from "../../constants/constants";
const { ACTIVE, SCHEDULED, COMPLETED } = Constants;
const { FUTURE, ONGOING, PAST } = EventStatus;
const {
  CONCLUDED_SESSION,
  ACTIVE_SESSION,
  NO_BATCHES,
  NO_PRESENTERS,
  CONCLUDED_MODULE,
  CONCLUDED_BOOTCAMP,
} = HelpTexts;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SessionsTable = ({
  sessionList,
  currentPage,
  totalPages,
  handlePageChange,
  viewMore,
  viewMoreId,
  handleViewMoreClick,
  handleEditSession,
  totalRecords,
  handleExercise,
  handleResourceTableOpen,
  viaTopic,
  handleOpenSendEmailModal,
  handleDeleteModalOpen,
  handleResourceModalOpen,
  isHr,
  module,
  disableEmail,
  isBootcampCompleted,
}) => {
  const isModuleCompleted = module?.status === COMPLETED;
  const { bootcampId, moduleId, topicId } = useParams();

  const isModuleSpoc = module?.permission?.spoc;
  const isModulePresenter = module?.permission?.sessionPresenter;
  const moduleStatusIsNotConcluded =
    module?.status === ACTIVE || module?.status === SCHEDULED;
  const canViewActions = isModuleSpoc || isModulePresenter;

  const security = useSelector((state) => state?.security);
  const roles = security?.roles;
  const [openAgenda, setOpenAgenda] = useState(false);
  const [agendaData, setAgendaData] = useState(null);
  const handleAgendaOpen = (agenda) => {
    setAgendaData(agenda);
    setOpenAgenda(true);
  };
  const handleAgendaClose = () => {
    setOpenAgenda(false);
    setAgendaData(null);
  };

  return (
    <>
      <TableContainer className="table-container">
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="table-head-container">
            <TableRow className="table-head-row">
              <TableCell
                align="left"
                className="padding-left-1"
                sx={{ minWidth: "10rem" }}
              >
                <Typography variant="font_13_bold">Name</Typography>
              </TableCell>
              {viaTopic && (
                <>
                  <TableCell
                    align="left"
                    className="padding-left-1"
                    sx={{ minWidth: "10rem" }}
                  >
                    <Typography variant="font_13_bold">Module</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="padding-left-1"
                    sx={{ minWidth: "10rem" }}
                  >
                    <Typography variant="font_13_bold">Bootcamp(s)</Typography>
                  </TableCell>
                </>
              )}
              <TableCell
                align="left"
                sx={{ minWidth: viaTopic ? "6.8rem" : "9rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">
                  {viaTopic ? "Session Date" : "Start Date/Time"}
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "6rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Duration</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "15rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">
                  Batch (Presenter, Co-Presenter)
                </Typography>
              </TableCell>
              {!viaTopic && (
                <TableCell
                  align="center"
                  sx={{ minWidth: "10rem" }}
                  className="padding-left-1"
                >
                  <Typography variant="font_13_bold">
                    Attendance
                    <BootstrapTooltip
                      title={<AttendanceLegends />}
                      placement="right"
                    >
                      <IconButton
                        sx={{
                          color: "rgb(0, 0, 0)",
                          padding: "0 0.125rem",
                          marginBottom: "0.125rem",
                        }}
                      >
                        <InfoIcon sx={{ fontSize: 18 }} />
                      </IconButton>
                    </BootstrapTooltip>
                  </Typography>
                </TableCell>
              )}
              <TableCell
                align="center"
                sx={{ minWidth: "7rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Questions</Typography>
              </TableCell>
              {!viaTopic && (
                <TableCell
                  align="center"
                  sx={{ minWidth: "10rem" }}
                  className="padding-left-1"
                >
                  <Typography variant="font_13_bold">
                    Exercise Status
                    <BootstrapTooltip
                      title={<ExerciseStatusLegends />}
                      placement="right"
                    >
                      <IconButton
                        sx={{
                          color: "rgb(0, 0, 0)",
                          padding: "0 0.125rem",
                          marginBottom: "0.125rem",
                        }}
                      >
                        <InfoIcon sx={{ fontSize: 18 }} />
                      </IconButton>
                    </BootstrapTooltip>
                  </Typography>
                </TableCell>
              )}
              <TableCell
                align="center"
                sx={{ minWidth: "7rem" }}
                className="padding-left-1 padding-right-2"
              >
                <Typography variant="font_13_bold">Resources</Typography>
              </TableCell>
              {!viaTopic && canViewActions && (
                <TableCell
                  align="center"
                  sx={{ minWidth: "8rem" }}
                  className="padding-left-1 padding-right-2"
                >
                  <Typography variant="font_13_bold">Actions</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody className="session-table-row">
            {sessionList?.map((row, index) => {
              const isSpoc = row?.permission?.spoc;
              const bootcampNames = row?.module?.bootcamps?.results;
              const startDate = dayjs(row?.startDate);
              const endDate = dayjs(row?.endDate);
              const duration = humanizeDuration(endDate.diff(startDate));
              const permission = row?.permission;
              const sessionStatus = checkDateTimeStatus(
                row?.startDate,
                row?.endDate
              );
              const sessionIsScheduled = sessionStatus === FUTURE;
              const sessionIsConcluded = sessionStatus === PAST;
              const sessionIsActive = sessionStatus === ONGOING;
              const isAllowed =
                permission?.sessionPresenter || permission?.spoc;
              const exerciseStatus = row?.countOfExercises?.PENDING
                ? "Pending"
                : row?.countOfExercises?.IN_REVIEW
                ? "In-Review"
                : row?.countOfExercises?.REVIEWED
                ? "Reviewed"
                : row?.countOfExercises?.REOPENED
                ? "Reopened"
                : "";
              const exerciseStatusVariant =
                exerciseStatus === "Pending"
                  ? "BLUE-TEXT"
                  : exerciseStatus === "In-Review"
                  ? "YELLOW-TEXT"
                  : exerciseStatus === "Reviewed"
                  ? "GREEN-TEXT"
                  : exerciseStatus === "Reopened"
                  ? "RED-TEXT"
                  : "";
              const attendanceStatus = row?.countOfAttendances?.NOT_MARKED
                ? "Not Marked"
                : row?.countOfAttendances?.PRESENT
                ? "Present"
                : row?.countOfAttendances?.ABSENT
                ? "Absent"
                : "";
              const attendanceStatusVariant =
                attendanceStatus === "Not Marked"
                  ? "BLUE-TEXT"
                  : attendanceStatus === "Absent"
                  ? "RED-TEXT"
                  : attendanceStatus === "Present"
                  ? "GREEN-TEXT"
                  : "";
              const sumOfPresenters = row?.presenters?.totalResults;
              const hasPresenter = row?.presenters?.results?.some(
                (item) =>
                  item?.leadPresenter !== null || item?.coPresenter !== null
              );
              const modules = row?.module?.name;
              const moduleIsConcluded = row?.module?.status === COMPLETED;
              const accessViaTopic = viaTopic && isSpoc;
              const canDeleteResource =
                (viaTopic && !moduleIsConcluded) ||
                (!viaTopic &&
                  (isAllowed || isHr) &&
                  moduleStatusIsNotConcluded);

              const sessionAgenda = row?.agenda;
              return (
                <TableRow className="table-body-row" hover key={index}>
                  <TableCell align="left" className="padding-left-1">
                    <BootstrapTooltip
                      title={viaTopic ? "Show Session Agenda" : "Show Session"}
                    >
                      <Link
                        to={
                          !viaTopic
                            ? `/bootcamp/${bootcampId}/module/${moduleId}/sessions/${row?.id}`
                            : undefined
                        }
                        onClick={
                          viaTopic
                            ? () => handleAgendaOpen(sessionAgenda)
                            : undefined
                        }
                      >
                        <Typography
                          color="secondary"
                          variant="font_12"
                          className="cursor-pointer"
                        >
                          <u> {row?.name}</u>
                        </Typography>
                      </Link>
                    </BootstrapTooltip>
                  </TableCell>
                  {viaTopic && (
                    <>
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">{modules}</Typography>
                      </TableCell>
                      <TableCell align="left" className="padding-left-1">
                        {bootcampNames?.map((bootcamp, index) => (
                          <Typography variant="font_12">
                            {bootcamp?.name}
                            {index < bootcampNames.length - 1 && <br />}{" "}
                          </Typography>
                        ))}
                      </TableCell>
                    </>
                  )}
                  <TableCell align="left" className="padding-left-1">
                    {viaTopic && (
                      <Typography variant="font_12">
                        {dayjs(startDate)?.format("DD/MM/YYYY")}
                      </Typography>
                    )}
                    {!viaTopic && (
                      <Typography variant="font_12">
                        {dayjs(startDate)?.format("DD/MM/YYYY hh:mm A")}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">{duration}</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    className="padding-left-1"
                    sx={{ textWrap: "nowrap" }}
                  >
                    <Collapse
                      in={viewMore && viewMoreId === row?.id}
                      collapsedSize={
                        sumOfPresenters > 5 ? 100 : sumOfPresenters * 20
                      }
                      sx={{
                        height: sumOfPresenters <= 5 ? "max-content" : 0,
                      }}
                    >
                      {row?.presenters?.results?.map((presenter, index) => {
                        const mode = presenter?.mode || presenter?.batch?.mode;
                        const meetingLink =
                          presenter?.meetingUrl || presenter?.batch?.meetingUrl;
                        const roomName =
                          presenter?.room?.name || presenter?.batch?.room?.name;
                        const getPresenterNames = (presenter) => {
                          const leadName =
                            presenter?.leadPresenter?.fullName || "";
                          const coName = presenter?.coPresenter?.fullName || "";
                          const deletedStyle = { color: "red" };
                          const leadPresenter = (
                            <span
                              style={
                                presenter?.leadPresenter?.deleted
                                  ? deletedStyle
                                  : {}
                              }
                            >
                              {leadName}
                            </span>
                          );
                          const coPresenter = (
                            <span
                              style={
                                presenter?.coPresenter?.deleted
                                  ? deletedStyle
                                  : {}
                              }
                            >
                              {coName}
                            </span>
                          );
                          return (
                            <>
                              {presenter?.batch?.name + " ("}
                              {leadName && coName ? (
                                <>
                                  {leadPresenter}, {coPresenter}
                                </>
                              ) : leadName ? (
                                leadPresenter
                              ) : coName ? (
                                coPresenter
                              ) : (
                                "-"
                              )}
                              {")"}
                            </>
                          );
                        };
                        return (
                          <React.Fragment key={index}>
                            <BootstrapTooltip
                              placement="right"
                              title={
                                mode && (
                                  <>
                                    <strong>Mode:</strong> {mode} <br />
                                    <strong>Location:</strong>{" "}
                                    {mode === "ONLINE" ? meetingLink : roomName}
                                  </>
                                )
                              }
                            >
                              <Typography variant="font_12">
                                {getPresenterNames(presenter)}
                              </Typography>
                            </BootstrapTooltip>
                            <br />
                          </React.Fragment>
                        );
                      })}
                    </Collapse>
                    {sumOfPresenters > 5 ? (
                      <Box
                        className="expand-more"
                        onClick={() => handleViewMoreClick(row?.id)}
                      >
                        <Typography
                          variant="font_9_bold"
                          color="secondary"
                          cursor="pointer"
                          sx={{ fontWeight: 600 }}
                        >
                          {viewMore && viewMoreId === row?.id
                            ? "VIEW LESS"
                            : "VIEW MORE"}
                        </Typography>
                        <ExpandMore
                          expand={viewMoreId === row?.id}
                          sx={{ padding: 0, verticalAlign: "sub" }}
                        >
                          <ExpandMoreIcon
                            color="secondary"
                            sx={{
                              fontSize: "1rem",
                            }}
                          />
                        </ExpandMore>
                      </Box>
                    ) : null}
                  </TableCell>
                  {!viaTopic &&
                    (permission?.attendee ? (
                      <TableCell align="center" className="padding-left-1">
                        {!attendanceStatus ? null : (
                          <Chip
                            label={attendanceStatus}
                            className="margin-right-half chip-padding-left-right same-size-chip"
                            variant={attendanceStatusVariant}
                          />
                        )}
                      </TableCell>
                    ) : (
                      <TableCell align="center" className="padding-left-1">
                        <Chip
                          label={
                            row?.countOfAttendancesForBootcamp?.NOT_MARKED
                              ? row?.countOfAttendancesForBootcamp?.NOT_MARKED
                              : 0
                          }
                          variant="BLUE"
                          className="margin-right-half chip-padding-left-right"
                          // style={{ marginBottom: "0.33rem" }}
                        />
                        <Chip
                          label={
                            row?.countOfAttendancesForBootcamp?.PRESENT
                              ? row?.countOfAttendancesForBootcamp?.PRESENT
                              : 0
                          }
                          variant="GREEN"
                          className="margin-right-half chip-padding-left-right"
                          // style={{ marginBottom: "0.33rem" }}
                        />
                        <Chip
                          className="margin-right-half chip-padding-left-right"
                          label={
                            row?.countOfAttendancesForBootcamp?.ABSENT
                              ? row?.countOfAttendancesForBootcamp?.ABSENT
                              : 0
                          }
                          variant="RED"
                          // style={{ marginBottom: "0.33rem" }}
                        />

                        {/* <br />
                        <Chip
                          label={
                            row?.countOfAttendances?.NOT_MARKED
                              ? row?.countOfAttendances?.NOT_MARKED
                              : 0
                          }
                          variant="BLUE"
                          className="margin-right-half chip-padding-left-right"
                        />
                        <Chip
                          label={
                            row?.countOfAttendances?.PRESENT
                              ? row?.countOfAttendances?.PRESENT
                              : 0
                          }
                          variant="GREEN"
                          className="margin-right-half chip-padding-left-right"
                        />
                        <Chip
                          className="margin-right-half chip-padding-left-right"
                          label={
                            row?.countOfAttendances?.ABSENT
                              ? row?.countOfAttendances?.ABSENT
                              : 0
                          }
                          variant="RED"
                        /> */}
                      </TableCell>
                    ))}
                  <TableCell align="center" className="padding-left-1">
                    {accessViaTopic || !viaTopic ? (
                      <BootstrapTooltip title="Show Questions">
                        <Typography
                          variant="font_12"
                          color="secondary"
                          sx={{
                            textDecoration: "underline",
                            fontWeight: 700,
                            cursor: "pointer",
                          }}
                          onClick={() => handleExercise(row?.id)}
                        >
                          {row?.exerciseQuestions?.totalResults
                            ? row?.exerciseQuestions?.totalResults
                            : 0}
                        </Typography>
                      </BootstrapTooltip>
                    ) : (
                      <Typography
                        variant="font_12"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        {row?.exerciseQuestions?.totalResults || 0}
                      </Typography>
                    )}
                  </TableCell>
                  {!viaTopic &&
                    (permission.attendee ? (
                      <TableCell align="center" className="padding-left-1">
                        {!exerciseStatus ? null : (
                          <Chip
                            label={exerciseStatus}
                            className="margin-right-half chip-padding-left-right same-size-chip"
                            variant={exerciseStatusVariant}
                          />
                        )}
                      </TableCell>
                    ) : (
                      <TableCell align="center" className="padding-left-1">
                        <Chip
                          label={
                            row?.countOfExercisesForBootcamp?.PENDING
                              ? row?.countOfExercisesForBootcamp?.PENDING
                              : 0
                          }
                          variant="BLUE"
                          className="margin-right-half chip-padding-left-right"
                          // style={{ marginBottom: "0.33rem" }}
                        />
                        <Chip
                          className="margin-right-half chip-padding-left-right"
                          label={
                            row?.countOfExercisesForBootcamp?.IN_REVIEW
                              ? row?.countOfExercisesForBootcamp?.IN_REVIEW
                              : 0
                          }
                          variant="YELLOW"
                          // style={{ marginBottom: "0.33rem" }}
                        />
                        <Chip
                          label={
                            row?.countOfExercisesForBootcamp?.REVIEWED
                              ? row?.countOfExercisesForBootcamp?.REVIEWED
                              : 0
                          }
                          variant="GREEN"
                          className="margin-right-half chip-padding-left-right"
                          // style={{ marginBottom: "0.33rem" }}
                        />
                        <Chip
                          label={
                            row?.countOfExercisesForBootcamp?.REOPENED
                              ? row?.countOfExercisesForBootcamp?.REOPENED
                              : 0
                          }
                          variant="RED"
                          className="margin-right-half chip-padding-left-right"
                          // style={{ marginBottom: "0.33rem" }}
                        />
                        {/* <br />
                        <Chip
                          label={
                            row?.countOfExercises?.PENDING
                              ? row?.countOfExercises?.PENDING
                              : 0
                          }
                          variant="BLUE"
                          className="margin-right-half chip-padding-left-right"
                        />
                        <Chip
                          className="margin-right-half chip-padding-left-right"
                          label={
                            row?.countOfExercises?.IN_REVIEW
                              ? row?.countOfExercises?.IN_REVIEW
                              : 0
                          }
                          variant="YELLOW"
                        />
                        <Chip
                          label={
                            row?.countOfExercises?.REVIEWED
                              ? row?.countOfExercises?.REVIEWED
                              : 0
                          }
                          variant="GREEN"
                          className="margin-right-half chip-padding-left-right"
                        />
                        <Chip
                          label={
                            row?.countOfExercises?.REOPENED
                              ? row?.countOfExercises?.REOPENED
                              : 0
                          }
                          variant="RED"
                          className="margin-right-half chip-padding-left-right"
                        /> */}
                      </TableCell>
                    ))}
                  <TableCell
                    align="center"
                    className="padding-left-1 padding-right-2"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {accessViaTopic || !viaTopic ? (
                        <BootstrapTooltip title="Show Resources">
                          <Button
                            sx={{ minWidth: 0 }}
                            onClick={(e) =>
                              handleResourceTableOpen(
                                e,
                                row?.id,
                                canDeleteResource,
                                isAllowed
                              )
                            }
                          >
                            <Typography
                              variant="font_12"
                              color="secondary"
                              sx={{
                                textDecoration: "underline",
                                fontWeight: 700,
                              }}
                            >
                              {row?.resources?.totalResults}
                            </Typography>
                          </Button>
                        </BootstrapTooltip>
                      ) : (
                        <Button sx={{ minWidth: 0, cursor: "text" }}>
                          <Typography
                            variant="font_12"
                            color="contrastText"
                            sx={{ fontWeight: 700 }}
                          >
                            {row?.resources?.totalResults}
                          </Typography>
                        </Button>
                      )}
                      {(accessViaTopic || (!viaTopic && isAllowed)) && (
                        <IconButtons
                          width={font_21}
                          height={font_21}
                          image={plus_dashed_active_icon}
                          disabledImage={plus_dashed_default_icon}
                          tooltip="Add Resource"
                          handleClick={() =>
                            handleResourceModalOpen(
                              row?.id,
                              isModuleSpoc && sessionIsScheduled
                            )
                          }
                          disabled={moduleIsConcluded}
                          disabledTooltip={
                            moduleIsConcluded ? "Module is completed" : null
                          }
                        />
                      )}
                    </Box>
                  </TableCell>
                  {!viaTopic && canViewActions && (
                    <TableCell
                      align="center"
                      className="padding-left-1 padding-right-2"
                    >
                      {(permission?.spoc || permission?.sessionPresenter) && (
                        <IconButtons
                          width={font_13_icon}
                          height={font_13_icon}
                          disabledImage={edit_disabled_icon}
                          image={edit_active_icon}
                          handleClick={() =>
                            handleEditSession(row, permission?.spoc)
                          }
                          classList="padding-right-1"
                          tooltip="Edit Session"
                          disabled={
                            isBootcampCompleted ||
                            isModuleCompleted ||
                            sessionIsConcluded
                          }
                          disabledTooltip={
                            isBootcampCompleted
                              ? CONCLUDED_BOOTCAMP
                              : isModuleCompleted
                              ? CONCLUDED_MODULE
                              : sessionIsConcluded
                              ? CONCLUDED_SESSION
                              : null
                          }
                        />
                      )}
                      {permission?.spoc && (
                        <>
                          <IconButtons
                            width={font_13_icon}
                            height={font_13_icon}
                            image={delete_active_icon}
                            disabledImage={delete_disabled_icon}
                            handleClick={() =>
                              handleDeleteModalOpen(row?.id, "SessionList")
                            }
                            tooltip="Delete Session"
                            classList="padding-right-1"
                            disabled={
                              isBootcampCompleted ||
                              isModuleCompleted ||
                              sessionIsConcluded ||
                              sessionIsActive
                            }
                            disabledTooltip={
                              isBootcampCompleted
                                ? CONCLUDED_BOOTCAMP
                                : isModuleCompleted
                                ? CONCLUDED_MODULE
                                : sessionIsConcluded
                                ? CONCLUDED_SESSION
                                : sessionIsActive
                                ? ACTIVE_SESSION
                                : ""
                            }
                          />
                          <IconButtons
                            width={font_13_icon}
                            height={font_13_icon}
                            image={message_active_icon}
                            disabledImage={disabled_msg_icon}
                            handleClick={() => handleOpenSendEmailModal(row)}
                            tooltip="Send Email"
                            disabledTooltip={
                              isBootcampCompleted
                                ? CONCLUDED_BOOTCAMP
                                : isModuleCompleted
                                ? CONCLUDED_MODULE
                                : sessionIsConcluded
                                ? CONCLUDED_SESSION
                                : sessionIsActive
                                ? ACTIVE_SESSION
                                : !disableEmail
                                ? NO_BATCHES
                                : !hasPresenter
                                ? NO_PRESENTERS
                                : null
                            }
                            classList="padding-right-1"
                            disabled={
                              isBootcampCompleted ||
                              isModuleCompleted ||
                              sessionIsConcluded ||
                              sessionIsActive ||
                              !disableEmail ||
                              !hasPresenter
                            }
                          />
                        </>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container className="table-pagination-container">
        <Typography variant="font_16">Total {totalRecords} Records</Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>

      <SimpleModal
        message="No agenda Available"
        open={openAgenda}
        handleClose={handleAgendaClose}
        data={agendaData}
        isAgenda={true}
      />
    </>
  );
};

export default SessionsTable;

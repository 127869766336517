import {
  Box,
  Grid,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IconButtons from "../../../components/IconButtons/IconButtons";
import {
  circle_save_change_icon,
  cross_red_icon,
  custom_edit_icon,
  disabled_circle_save_change_icon,
  disabled_msg_icon,
  edit_active_icon,
  edit_disabled_icon,
  message_active_icon,
} from "../../../constants/icons";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPresenters,
  clearRoomList,
  clearUserList,
  getPresenters,
  getRoomList,
  getUsersList,
  updatePresenterList,
} from "./../../../store/SessionView/presentersTab";
import SimpleAutoComplete from "../../../components/SimpleAutoComplete/SimpleAutoComplete";
import {
  checkDateTimeStatus,
  font_13_icon,
  getLatestDate,
  hasDetailsChanged,
  isValidUrlFormat,
} from "../../../utils/utils";
import DateTimePickerComponent from "../../../components/DateTimePickerComponent/DateTimePickerComponent";
import dayjs from "dayjs";
import Loader from "../../../components/Loader/Loader";
import SendEmailModal from "../../../components/SendEmailModal/SendEmailModal";
import NoDataAvailable from "../../../components/NoDataAvailable/NoDataAvailable";
import UserTableAvatar from "../../../components/UserTableAvatar/UserTableAvatar";
import BootstrapTooltip from "../../../components/BootstrapTooltip/BootstrapTooltip";
import Constants, { HelpTexts } from "../../../constants/constants";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Asia/Kolkata";

const { COMPLETED } = Constants;

const initialPresenter = {
  sessionBatchPresenterId: null,
  batch: null,
  presenter: null,
  coPresenter: null,
  startDate: null,
  mode: null,
  room: null,
  meetingUrl: null,
  endDate: null,
};

const initialPresenterError = {
  presenter: null,
  coPresenter: null,
  mode: null,
  meetingUrl: null,
  roomId: null,
};

const {
  CONCLUDED_BOOTCAMP,
  CONCLUDED_MODULE,
  CONCLUDED_SESSION,
  ACTIVE_SESSION,
  NO_PRESENTERS,
  ENTER_VALID_URL,
} = HelpTexts;

const PresentersTab = ({ currentBootcamp }) => {
  const isBootcampCompleted = currentBootcamp?.status === COMPLETED;
  const params = useParams();
  const dispatch = useDispatch();
  const presenters = useSelector((state) => {
    return state?.presentersTab;
  });
  const Details = useSelector((state) => {
    return state?.detailsTab;
  });
  const modeOptions = useSelector(
    (state) => state?.utility?.constants?.bootcampModes
  );
  const session = Details?.details?.data;
  const isSpoc = session?.permission?.spoc;
  const canViewActions = isSpoc;
  const isModuleCompleted = session?.module?.status === COMPLETED;
  const userLoadingApi = presenters?.userLoading;
  const loading = presenters?.loading;
  const [isLoading, setIsLoading] = useState(true);
  /////////////////  for selecting presenter and Co-presenter dropdown list ////////////////////
  const usersList = presenters?.usersList?.data?.results;
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////// for table List /////////////////////////////////////////////////////////////////////
  const presentersList = presenters?.presenters?.data?.results;
  const presentersCount = presenters?.presenters?.data?.totalResults;
  const roomList = presenters?.roomList?.data?.results;

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////// edit row ///////////////////////////////////////
  const [rowId, setRowId] = useState(null);
  const [rowDetails, setRowDetails] = useState(initialPresenter);
  const [oldStartDate, setOldStartDate] = useState(null);
  const [presenterApiError, setPresenterApiError] = useState(false);
  const [errors, setErrors] = useState(initialPresenterError);

  const isValidUrl = () => {
    const isValidUrl =
      rowDetails?.meetingUrl && isValidUrlFormat(rowDetails?.meetingUrl);
    return isValidUrl;
  };

  const validateFields = (rowDetails) => {
    const { presenter, mode, meetingUrl, room } = rowDetails;
    const newErrors = {
      presenter: presenter ? "" : "Presenter is required",
      meetingUrl:
        mode?.key === "ONLINE" ? (meetingUrl ? "" : "URL is required") : "",
      room: mode?.key === "OFFLINE" ? (room?.id ? "" : "Room is required") : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleRowEditOpen = (presenter) => {
    setOldStartDate(
      dayjs(presenter?.startDate).isValid()
        ? dayjs(presenter?.startDate)
        : dayjs(presenter?.session?.startDate)
    );
    const modeStatus = modeOptions?.filter(
      (e) => e?.key === (presenter?.mode || presenter?.batch?.mode)
    );
    setPresenterApiError(false);
    setErrors(initialPresenterError);
    setRowDetails({
      sessionBatchPresenterId: presenter?.id,
      presenter: presenter?.leadPresenter?.deleted
        ? null
        : presenter?.leadPresenter,
      coPresenter: presenter?.coPresenter?.deleted
        ? null
        : presenter?.coPresenter,
      startDate: dayjs(presenter?.startDate).isValid()
        ? dayjs(presenter?.startDate)
        : dayjs(presenter?.session?.startDate),
      ...(modeStatus[0]?.key && {
        mode: modeStatus[0],
      }),
      meetingUrl: presenter?.meetingUrl || presenter?.batch?.meetingUrl,
      room: presenter?.room || presenter?.batch?.room,
      endDate: dayjs(presenter?.endDate).isValid()
        ? dayjs(presenter?.endDate)
        : dayjs(presenter?.session?.endDate),
    });
    setPreviousDetails((prevDetails) => ({
      ...prevDetails,
      room: presenter?.room || presenter?.batch?.room,
      meetingUrl: presenter?.meetingUrl || presenter?.batch?.meetingUrl,
    }));
    setRowId(presenter?.id);
  };
  const handleRowEditClose = () => {
    setRowId(null);
    setRowDetails(initialPresenter);
    setPresenterApiError(false);
    setErrors(initialPresenterError);
  };
  const handlePresenterChange = (name, value) => {
    setPresenterApiError(false);
    setErrors((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
    if (name === "mode") {
      setRowDetails((prev) => {
        return {
          ...prev,
          [name]: modeOptions?.filter((e) => e?.key === value)[0],
        };
      });
    } else {
      setRowDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };
  const submitRowEdit = async () => {
    let newEndDate;
    let apiValue;
    if (!dayjs(oldStartDate).isSame(dayjs(rowDetails?.startDate))) {
      const startDate = dayjs(oldStartDate);
      const endDate = dayjs(rowDetails?.endDate);
      const duration = Math.abs(endDate.diff(startDate, "millisecond", true));
      newEndDate = dayjs(rowDetails?.startDate)?.add(duration, "millisecond");
    }
    const finalEndDate = dayjs(
      dayjs(oldStartDate) !== rowDetails?.startDate
        ? newEndDate
        : rowDetails?.endDate
    );
    const data = {
      sessionBatchPresenterId: rowId,
      leadPresenterId: rowDetails?.presenter?.id,
      coPresenterId: rowDetails?.coPresenter?.id,
      startDate: dayjs(rowDetails?.startDate).isValid()
        ? dayjs(rowDetails?.startDate)?.tz(tz)?.format()
        : undefined,
      mode: rowDetails?.mode?.key,
      ...(rowDetails?.mode?.key === "ONLINE" && {
        meetingUrl: rowDetails?.meetingUrl,
      }),
      ...(rowDetails?.mode?.key === "OFFLINE" && {
        roomId: rowDetails?.room?.id,
      }),
      endDate: finalEndDate?.isValid() ? finalEndDate?.tz(tz)?.format() : null,
    };

    if (validateFields(rowDetails)) {
      if (rowDetails?.mode?.key === "ONLINE" && isValidUrl()) {
        apiValue = await dispatch(updatePresenterList(data));
        if (apiValue?.error?.message === "Rejected") {
          setPresenterApiError(true);
        } else {
          await dispatch(
            getPresenters({
              sessionId: params?.sessionId,
            })
          );
          setRowId(null);
          setRowDetails(initialPresenter);
        }
      } else if (rowDetails?.mode?.key === "OFFLINE") {
        apiValue = await dispatch(updatePresenterList(data));
        if (apiValue?.error?.message === "Rejected") {
          setPresenterApiError(true);
        } else {
          await dispatch(
            getPresenters({
              sessionId: params?.sessionId,
            })
          );
          setRowId(null);
          setRowDetails(initialPresenter);
        }
      } else if (
        rowDetails?.mode?.key === null ||
        rowDetails?.mode?.key === undefined
      ) {
        apiValue = await dispatch(updatePresenterList(data));
        if (apiValue?.error?.message === "Rejected") {
          setPresenterApiError(true);
        } else {
          await dispatch(
            getPresenters({
              sessionId: params?.sessionId,
            })
          );
          setRowId(null);
          setRowDetails(initialPresenter);
        }
      }
    }
  };
  const [initialPresenterDetails, setInitialPresenterDetails] =
    useState(rowDetails);

  useEffect(() => {
    setInitialPresenterDetails(rowDetails);
  }, [rowDetails?.sessionBatchPresenterId]);

  const presenterDetailsChanged = hasDetailsChanged(
    initialPresenterDetails,
    rowDetails
  );
  //////////////// handle SEnd Email Modal /////////////////////
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openEmailModuleId, setOpenEmailModuleId] = useState();
  const [openEmailSessionId, setOpenEmailSessionId] = useState();
  const [openEmailBatches, setOpenEmailBatches] = useState([]);

  const handleOpenSendEmailModal = (data) => {
    const batches = [];
    batches.push(data?.batch);
    const moduleId = Details?.details?.data?.module?.id;
    setOpenEmailModuleId(moduleId);
    setOpenEmailSessionId(data?.session?.id);
    setOpenEmailBatches(batches);
    setOpenEmailModal(true);
  };

  const handleCloseSendEmailModal = () => {
    setOpenEmailModal(false);
    setOpenEmailModuleId();
    setOpenEmailSessionId();
    setOpenEmailBatches([]);
  };
  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(
        getPresenters({
          sessionId: params?.sessionId,
        })
      );
      setIsLoading(false);
    };
    fetchData();
    return () => {
      dispatch(clearPresenters());
      dispatch(clearRoomList());
      dispatch(clearUserList());
    };
  }, [dispatch, params?.sessionId]);

  useEffect(() => {
    if (rowId && canViewActions) {
      if (!presenters?.usersList?.data?.results) {
        dispatch(
          getUsersList({
            notAttendeeInModuleId: Details?.details?.data?.module?.id,
          })
        );
      }
      if (!presenters?.roomList?.data?.results) {
        dispatch(getRoomList());
      }
    }
  }, [rowId]);

  const [previousDetails, setPreviousDetails] = useState(initialPresenter);

  useEffect(() => {
    if (rowDetails?.mode?.key === "ONLINE") {
      setRowDetails({
        ...rowDetails,
        meetingUrl: previousDetails?.meetingUrl,
        room: previousDetails?.room,
      });
    }
    if (rowDetails?.mode?.key === "OFFLINE") {
      setRowDetails({
        ...rowDetails,
        room: previousDetails?.room,
        meetingUrl: previousDetails?.meetingUrl,
      });
    }
  }, [rowDetails?.mode?.key]);

  if (loading && isLoading) return <Loader />;
  return (
    <>
      {presentersCount === 0 ? (
        <Grid container className="no-data-container tabs-no-data">
          <NoDataAvailable
            imgHeight={4}
            imgWidth={4.5}
            message="No Presenters Available"
          />
        </Grid>
      ) : (
        <>
          {userLoadingApi && <Loader />}
          <TableContainer className="table-container" sx={{ mb: 2 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="table-head-container">
                <TableRow className="table-head-row">
                  <TableCell
                    align="left"
                    className="padding-left-1"
                    sx={{ minWidth: "8rem" }}
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Batch</Box>
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: "12rem" }}>
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Presenter</Box>
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: "12rem" }}>
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Co-Presenter</Box>
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: "12rem" }}>
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Start Date/Time</Box>
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: "5rem" }}>
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Mode</Box>
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: "11.875rem" }}>
                    <Typography variant="font_13_bold">
                      Meeting Room/URL
                    </Typography>
                  </TableCell>
                  {canViewActions && (
                    <TableCell align="center" sx={{ minWidth: "8rem" }}>
                      <Typography variant="font_13_bold">Actions</Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody className="presenters-table-body">
                {presentersList?.map((presenter, index) => {
                  const presenterName = presenter?.leadPresenter?.fullName;
                  const presenterEmail = presenter?.leadPresenter?.email;
                  const presenterImage =
                    presenter?.leadPresenter?.profilePictureUrl;
                  const presenterEmployeeCode =
                    presenter?.leadPresenter?.employeeCode;
                  const presenterDeleted = presenter?.leadPresenter?.deleted;

                  const CoPresenterName = presenter?.coPresenter?.fullName;
                  const CoPresenterEmail = presenter?.coPresenter?.email;
                  const CoPresenterImage =
                    presenter?.coPresenter?.profilePictureUrl;
                  const CoPresenterEmployeeCode =
                    presenter?.coPresenter?.employeeCode;
                  const CoPresenterDeleted = presenter?.coPresenter?.deleted;

                  const batchName = presenter?.batch?.name;
                  const mode = presenter?.mode || presenter?.batch?.mode;
                  const isEditedMode = !!presenter?.mode;
                  const meetingLink =
                    presenter?.meetingUrl || presenter?.batch?.meetingUrl;
                  const isEditedLink = !!presenter?.meetingUrl;
                  const roomName =
                    presenter?.room?.name || presenter?.batch?.room?.name;
                  const isEditedRoom = !!presenter?.room?.name;

                  const startDate = dayjs(
                    presenter?.startDate || presenter?.session?.startDate
                  )?.format("DD/MM/YYYY hh:mm A");
                  const isEditedStartDate = !!presenter?.startDate;

                  const endDate = presenter?.endDate
                    ? presenter?.endDate
                    : presenter?.session?.endDate;

                  let duration;
                  if (presenter?.endDate) {
                    duration = Math.abs(
                      dayjs(presenter?.endDate).diff(
                        dayjs(presenter?.startDate),
                        "millisecond"
                      )
                    );
                  } else {
                    duration = Math.abs(
                      dayjs(presenter?.session?.endDate).diff(
                        dayjs(presenter?.session?.startDate),
                        "millisecond"
                      )
                    );
                  }

                  const newStartDate =
                    presenter?.startDate || presenter?.session?.startDate;
                  const editedEndDate = dayjs(newStartDate)?.add(
                    duration,
                    "millisecond"
                  );
                  const endDateTimeAfterCurrent = dayjs(editedEndDate).isAfter(
                    dayjs()
                  );
                  const sessionIsActive =
                    checkDateTimeStatus(newStartDate, editedEndDate) ===
                    "ongoing";

                  return (
                    <TableRow className="table-body-row" key={index}>
                      {rowId === presenter?.id ? (
                        <>
                          <TableCell className="padding-left-1">
                            <Typography variant="font_12">
                              {batchName}
                            </Typography>
                          </TableCell>
                          <TableCell className="padding-right-1 row-contains-error">
                            <Box className="margin-bottom-3">
                              <SimpleAutoComplete
                                placeholder="Select Presenter *"
                                options={
                                  usersList?.filter(
                                    (user) =>
                                      user?.id !== rowDetails?.coPresenter?.id
                                  ) || []
                                }
                                onChange={(event, newValue) =>
                                  handlePresenterChange("presenter", newValue)
                                }
                                getOptionLabel={(option) =>
                                  `${option?.fullName || ""} ${
                                    option?.email || ""
                                  }`
                                }
                                value={rowDetails?.presenter}
                                renderOption={(props, option, value) => {
                                  return (
                                    <li
                                      {...props}
                                      key={props?.id}
                                      className={
                                        option?.id === rowDetails?.presenter?.id
                                          ? "multi-tag-edit-list-selected"
                                          : "multi-tag-style"
                                      }
                                    >
                                      <Box
                                        lineHeight={1.1}
                                        sx={{ fontSize: "0.75rem" }}
                                      >
                                        <Box>{`${option?.fullName}`}</Box>
                                        <Typography
                                          variant="grayEmail"
                                          className="filter-title "
                                        >
                                          {`${option?.email}`}
                                        </Typography>
                                      </Box>
                                    </li>
                                  );
                                }}
                                required={true}
                                error={!!errors?.presenter || presenterApiError}
                                helperText={errors?.presenter}
                                disableClearable={true}
                                tooltipTitle={rowDetails?.presenter?.fullName}
                              />
                            </Box>
                          </TableCell>
                          <TableCell className="padding-right-1 row-contains-error">
                            <Box className="margin-bottom-3">
                              <SimpleAutoComplete
                                placeholder="Select Co-Presenter"
                                options={
                                  usersList?.filter(
                                    (it) => it?.id !== rowDetails?.presenter?.id
                                  ) || []
                                }
                                onChange={(event, newValue) =>
                                  handlePresenterChange("coPresenter", newValue)
                                }
                                getOptionLabel={(option) =>
                                  `${option?.fullName || ""} ${
                                    option?.email || ""
                                  }`
                                }
                                value={rowDetails?.coPresenter}
                                renderOption={(props, option, value) => {
                                  return (
                                    <li
                                      {...props}
                                      key={props?.id}
                                      className={
                                        option?.id ===
                                        rowDetails?.coPresenter?.id
                                          ? "multi-tag-edit-list-selected"
                                          : "multi-tag-style"
                                      }
                                    >
                                      <Box
                                        lineHeight={1.1}
                                        sx={{ fontSize: "0.75rem" }}
                                      >
                                        <Box>{`${option?.fullName}`}</Box>
                                        <Typography
                                          variant="grayEmail"
                                          className="filter-title "
                                        >
                                          {`${option?.email}`}
                                        </Typography>
                                      </Box>
                                    </li>
                                  );
                                }}
                                error={presenterApiError}
                                disabled={!rowDetails?.presenter}
                                tooltipTitle={rowDetails?.coPresenter?.fullName}
                              />
                            </Box>
                          </TableCell>
                          <TableCell className="padding-right-1 presenters-start-date row-contains-error">
                            <BootstrapTooltip
                              title={
                                sessionIsActive
                                  ? "Session is already started."
                                  : null
                              }
                            >
                              <Box>
                                <DateTimePickerComponent
                                  disabled={sessionIsActive}
                                  format="DD/MM/YYYY hh:mm A"
                                  onChange={(e) =>
                                    handlePresenterChange("startDate", e)
                                  }
                                  minDate={getLatestDate(
                                    presenter?.session?.startDate
                                  )}
                                  disablePast={true}
                                  value={rowDetails?.startDate}
                                  views={["year", "day", "hours", "minutes"]}
                                  error={presenterApiError}
                                  classList="margin-bottom-5"
                                  closeOnSelect={false}
                                />
                              </Box>
                            </BootstrapTooltip>
                          </TableCell>
                          <TableCell className="padding-right-1 row-contains-error presenters-mode">
                            <Box className="margin-bottom-3">
                              <BootstrapTooltip
                                title={rowDetails?.mode?.value}
                                placement="top"
                              >
                                <TextField
                                  select
                                  color="secondary"
                                  placeholder="Mode"
                                  className="mode-multiselect"
                                  sx={{
                                    width: "7rem",
                                  }}
                                  margin="dense"
                                  error={presenterApiError}
                                  variant="outlined"
                                  onChange={(event) => {
                                    handlePresenterChange(
                                      "mode",
                                      event?.target?.value
                                    );
                                  }}
                                  value={rowDetails?.mode?.key}
                                >
                                  {modeOptions.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.key}
                                    >
                                      {option.value}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </BootstrapTooltip>
                            </Box>
                          </TableCell>
                          {rowDetails?.mode?.key === "ONLINE" ? (
                            <TableCell className="padding-right-1 row-contains-error presenters-meet">
                              <TextField
                                placeholder="Meeting Url*"
                                fullWidth
                                color="secondary"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                value={rowDetails?.meetingUrl}
                                onChange={(e) =>
                                  handlePresenterChange(
                                    "meetingUrl",
                                    e?.target?.value
                                  )
                                }
                                required={rowDetails?.mode?.key === "ONLINE"}
                                error={
                                  (rowDetails?.meetingUrl
                                    ? !isValidUrl()
                                    : false) ||
                                  !!errors?.meetingUrl ||
                                  presenterApiError
                                }
                                helperText={
                                  (rowDetails?.meetingUrl
                                    ? !isValidUrl() && ENTER_VALID_URL
                                    : false) || errors?.meetingUrl
                                }
                                disableClearable={true}
                                disabled={rowDetails?.mode?.key !== "ONLINE"}
                              />
                            </TableCell>
                          ) : (
                            <TableCell className="padding-right-1 row-contains-error">
                              <Box className="margin-bottom-3">
                                <SimpleAutoComplete
                                  placeholder="Select Room *"
                                  options={roomList || []}
                                  onChange={(event, newValue) =>
                                    handlePresenterChange("room", newValue)
                                  }
                                  getOptionLabel={(option) => option?.name}
                                  value={rowDetails?.room}
                                  required={rowDetails?.mode?.key === "OFFLINE"}
                                  disabled={rowDetails?.mode?.key !== "OFFLINE"}
                                  error={!!errors?.room || presenterApiError}
                                  helperText={errors?.room}
                                  disableClearable={true}
                                  tooltipTitle={rowDetails?.room?.name}
                                  renderOption={(props, option, value) => {
                                    return (
                                      <li
                                        {...props}
                                        key={props?.id}
                                        className={
                                          option?.id === rowDetails?.room?.id
                                            ? "multi-tag-edit-list-selected"
                                            : "multi-tag-style"
                                        }
                                      >
                                        <Box lineHeight={1.1}>
                                          <Box>{`${option?.name}`}</Box>
                                        </Box>
                                      </li>
                                    );
                                  }}
                                />
                              </Box>
                            </TableCell>
                          )}
                          <TableCell align="center">
                            <Box>
                              <IconButtons
                                height={font_13_icon}
                                width={font_13_icon}
                                image={circle_save_change_icon}
                                disabledImage={disabled_circle_save_change_icon}
                                disabled={!presenterDetailsChanged}
                                classList="padding-right-1"
                                handleClick={submitRowEdit}
                              />
                              <IconButtons
                                height={font_13_icon}
                                width={font_13_icon}
                                image={cross_red_icon}
                                handleClick={handleRowEditClose}
                                classList="padding-right-1"
                              />
                            </Box>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell className="padding-left-1">
                            <Typography variant="font_12">
                              {batchName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <UserTableAvatar
                              imgAlt={presenterName}
                              imgSrc={presenterImage}
                              href={presenterEmployeeCode}
                              label={presenterName}
                              subLabel={presenterEmail}
                              deleted={presenterDeleted}
                            />
                          </TableCell>
                          <TableCell>
                            <UserTableAvatar
                              imgAlt={CoPresenterName}
                              imgSrc={CoPresenterImage}
                              href={CoPresenterEmployeeCode}
                              label={CoPresenterName}
                              subLabel={CoPresenterEmail}
                              deleted={CoPresenterDeleted}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant="font_12">
                              {startDate}
                            </Typography>
                            {isEditedStartDate && (
                              <IconButtons
                                width={font_13_icon}
                                height={font_13_icon}
                                image={custom_edit_icon}
                                disabledImage={custom_edit_icon}
                                classList="custom-data-margin"
                                disabled={isEditedStartDate}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Typography variant="font_12">{mode}</Typography>

                            {isEditedMode && (
                              <IconButtons
                                width={font_13_icon}
                                height={font_13_icon}
                                image={custom_edit_icon}
                                disabledImage={custom_edit_icon}
                                classList="custom-data-margin"
                                disabled={isEditedMode}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {mode === "ONLINE" ? (
                              <>
                                <Link
                                  color="secondary"
                                  variant="font_12"
                                  underline="always"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={meetingLink}
                                  sx={{ wordBreak: "break-all !important" }}
                                >
                                  {meetingLink}
                                </Link>
                                {isEditedLink && (
                                  <IconButtons
                                    width={font_13_icon}
                                    height={font_13_icon}
                                    image={custom_edit_icon}
                                    disabledImage={custom_edit_icon}
                                    classList="custom-data-margin"
                                    disabled={isEditedLink}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <Typography
                                  variant="font_12"
                                  sx={{ wordBreak: "break-word" }}
                                >
                                  {roomName}
                                </Typography>
                                {isEditedRoom && (
                                  <IconButtons
                                    width={font_13_icon}
                                    height={font_13_icon}
                                    image={custom_edit_icon}
                                    disabledImage={custom_edit_icon}
                                    classList="custom-data-margin"
                                    disabled={isEditedRoom}
                                  />
                                )}
                              </>
                            )}
                          </TableCell>
                          {canViewActions && (
                            <TableCell align="center">
                              <Box>
                                <IconButtons
                                  height={font_13_icon}
                                  width={font_13_icon}
                                  image={message_active_icon}
                                  classList="padding-right-1"
                                  disabled={
                                    isBootcampCompleted ||
                                    isModuleCompleted ||
                                    !endDateTimeAfterCurrent ||
                                    sessionIsActive ||
                                    !presenterName
                                  }
                                  disabledImage={disabled_msg_icon}
                                  tooltip="Send Email"
                                  handleClick={() =>
                                    handleOpenSendEmailModal(presenter)
                                  }
                                  disabledTooltip={
                                    isBootcampCompleted
                                      ? CONCLUDED_BOOTCAMP
                                      : isModuleCompleted
                                      ? CONCLUDED_MODULE
                                      : !endDateTimeAfterCurrent
                                      ? CONCLUDED_SESSION
                                      : sessionIsActive
                                      ? ACTIVE_SESSION
                                      : !presenterName
                                      ? NO_PRESENTERS
                                      : null
                                  }
                                />
                                <IconButtons
                                  height={font_13_icon}
                                  width={font_13_icon}
                                  image={edit_active_icon}
                                  disabledImage={edit_disabled_icon}
                                  handleClick={() =>
                                    handleRowEditOpen(presenter)
                                  }
                                  classList="padding-right-1"
                                  tooltip="Edit Presenter"
                                  disabled={
                                    isBootcampCompleted ||
                                    isModuleCompleted ||
                                    !endDateTimeAfterCurrent
                                  }
                                  disabledTooltip={
                                    isBootcampCompleted
                                      ? CONCLUDED_BOOTCAMP
                                      : isModuleCompleted
                                      ? CONCLUDED_MODULE
                                      : !endDateTimeAfterCurrent
                                      ? CONCLUDED_SESSION
                                      : null
                                  }
                                />
                              </Box>
                            </TableCell>
                          )}
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <SendEmailModal
        open={openEmailModal}
        handleClose={handleCloseSendEmailModal}
        moduleId={openEmailModuleId}
        sessionId={openEmailSessionId}
        batches={openEmailBatches}
      />
    </>
  );
};

export default PresentersTab;
